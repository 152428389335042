import { Tooltip as BsTooltip } from "bootstrap";
import React, { useEffect, useRef } from "react";

export const Tooltip = (p: { children: JSX.Element; title: any }) => {
	const childRef = useRef(undefined as unknown as Element);

	useEffect(() => {
		const t = new BsTooltip(childRef.current, {
			title: p.title,
			html: true,
			placement: "top",
			trigger: "hover",
		});
		return () => t.dispose();
	}, [p.title]);

	return React.cloneElement(p.children, { ref: childRef });
};

export const setTooltipContent = (id: string, title: any) => {
	const tooltip = BsTooltip.getInstance(id);
	tooltip?.setContent({ ".tooltip-inner": title });
};
