import { createSearchParams } from "react-router-dom";
import apiService from "./APIService";
import * as Model from "../model";

interface GetUsersResponseData {
	message: string;
	items: Model.User[];
	enabled_count: number;
	pagination: Model.Pagination;
}

interface FileUploadResponseData {
	message: string;
	image_config?: Model.ImageConfig;
}

class UserService {
	getUsers(page?: number, params?: { name?: string }): Promise<GetUsersResponseData | undefined> {
		const searchParams = createSearchParams(params);
		const query = Array.from(searchParams).length > 0 ? `?${searchParams}` : "";

		return apiService
			.getApiInstance()
			.get(
				apiService.getURL() + "/user/list" + (typeof page === "number" && page > 1 ? `/${page}` : "") + query,
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
				response.data.items = response.data.users;
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	update(
		id: number,
		params: { channels?: number[]; imageConfig?: Model.ImageConfig }
	): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		const formData = new FormData();
		if (params.channels) formData.append("channels", JSON.stringify(params.channels));
		if (params.imageConfig) formData.append("image_config", JSON.stringify(params.imageConfig));

		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/user/${id.toString()}/update`, formData, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	uploadFiles(id: number, params: { background?: File; font?: File }): Promise<FileUploadResponseData | undefined> {
		const formData = new FormData();
		if (params.background) formData.append("background", params.background);
		if (params.font) formData.append("font", params.font);

		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/user/${id.toString()}/uploadFiles`, formData, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	imageConfig(id?: number): Promise<Model.ImageConfig | undefined> {
		return apiService
			.getApiInstance()
			.get(`${apiService.getURL()}/user/${id ? id.toString() + "/" : ""}imageConfig`, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data.image_config;
			})
			.catch(apiService.errorHandler);
	}

	delete(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/user/${id.toString()}/delete`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}
}

export default new UserService();
