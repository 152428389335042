import React, { Component } from "react";
import { createSearchParams } from "react-router-dom";
import { RouterProps } from "../../common/withRouter";
import * as Model from "../../model";

interface PaginationProps extends RouterProps {
	data?: Model.Pagination;
}

class Pagination extends Component<PaginationProps, any> {
	constructor(props: PaginationProps) {
		super(props);

		this.state = {};
	}

	componentDidMount() {}

	render() {
		if (!this.props.data || this.props.data?.pageCount < 2) {
			return undefined;
		}

		const firstPage = Math.min(Math.max(this.props.data!.currentPage - 1, 1), this.props.data!.pageCount - 2);
		const getSearchParamsForPage = (page?: number): URLSearchParams => {
			let params = new URLSearchParams(this.props.search.params);
			if (page) {
				params.set("page", page!.toString());
			} else {
				params.delete("page");
			}
			return params;
		};

		return (
			<ul className="pagination justify-content-center mt-3 mb-3">
				<li className={`page-item ${this.props.data!.currentPage > 1 ? "" : "disabled"}`}>
					<a
						className="page-link"
						href="/locations"
						tabIndex={this.props.data!.currentPage > 1 ? undefined : -1}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							event.preventDefault();
							this.props.search.set(getSearchParamsForPage());
						}}
					>
						<span aria-hidden="true">&laquo;</span> First
					</a>
				</li>
				<li className={`page-item ${this.props.data!.currentPage > 1 ? "" : "disabled"}`}>
					<a
						className="page-link"
						href={`/locations?${createSearchParams(
							getSearchParamsForPage(this.props.data!.currentPage - 1)
						)}`}
						tabIndex={this.props.data!.currentPage > 1 ? undefined : -1}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							event.preventDefault();
							this.props.search.set(getSearchParamsForPage(this.props.data!.currentPage - 1));
						}}
					>
						<span aria-hidden="true">&lsaquo;</span> Prev
					</a>
				</li>
				{[firstPage, firstPage + 1, firstPage + 2].map((value: number, index: number) => {
					if (value === 0) return undefined;

					return (
						<li
							className={`page-item ${this.props.data!.currentPage === value ? "active" : ""}`}
							key={index}
						>
							<a
								className="page-link"
								href={`/locations?${createSearchParams(getSearchParamsForPage(value))}`}
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									event.preventDefault();
									this.props.search.set(getSearchParamsForPage(value));
								}}
							>
								{value}
							</a>
						</li>
					);
				})}
				<li
					className={`page-item ${
						this.props.data!.currentPage === this.props.data!.pageCount ? "disabled" : ""
					}`}
				>
					<a
						className="page-link"
						href={`/locations?${createSearchParams(getSearchParamsForPage(this.props.data!.currentPage+1))}`}
						tabIndex={this.props.data!.currentPage === this.props.data!.pageCount ? -1 : undefined}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							event.preventDefault();
							this.props.search.set(getSearchParamsForPage(this.props.data!.currentPage+1));
						}}
					>
						Next <span aria-hidden="true">&rsaquo;</span>
					</a>
				</li>
				<li
					className={`page-item ${
						this.props.data!.currentPage === this.props.data!.pageCount ? "disabled" : ""
					}`}
				>
					<a
						className="page-link"
						href={`/locations?${createSearchParams(getSearchParamsForPage(this.props.data!.pageCount))}`}
						tabIndex={this.props.data!.currentPage === this.props.data!.pageCount ? -1 : undefined}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							event.preventDefault();
							this.props.search.set(getSearchParamsForPage(this.props.data!.pageCount));
						}}
					>
						Last <span aria-hidden="true">&raquo;</span>
					</a>
				</li>
			</ul>
		);
	}
}

export default Pagination;
