import { Toast } from "bootstrap";

export enum ToastType {
	Success,
	Error,
}

export const showToast = (type: ToastType, message: string) => {
	var id: string = "";
	switch (type) {
		case ToastType.Success:
			id = "success-toast";
			break;
		case ToastType.Error:
			id = "error-toast";
			break;
		default:
			throw new Error("Failed to show toast.");
	}

	const toastElement = document.getElementById(id)?.cloneNode(true) as HTMLElement;
	toastElement.removeAttribute("id");
	toastElement.querySelector("span")!.innerText = message;

	const toastStackContainer = document.getElementById("toast-stack-container");
	toastStackContainer?.append(toastElement);

	const toast = new Toast(toastElement);
	toast.show();
};
