import React, { Component } from "react";
import * as Model from "../../model";
import { formatDistanceToNowStrict, intlFormat } from "date-fns";
import LocationService from "../../services/LocationService";

import { RouterProps, withRouter } from "../../common/withRouter";
import ExtendedList from "../common/ExtendedList";
import ExtendedListItem from "../common/ExtendedListItem";
import { createLocalDate } from "../../common/datetimeHelper";
import { setMetadata } from "../../common/metadataHelper";
import { Tooltip } from "../common/Tooltip";

interface LocationListState {
	reload: number;
}

class LocationList extends Component<RouterProps, LocationListState> {
	interval?: NodeJS.Timer;

	constructor(props: RouterProps) {
		super(props);

		this.state = {
			reload: 0,
		};
	}

	componentDidMount() {
		setMetadata({ title: "Locations" });

		this.interval = setInterval(() => {
			this.setState({
				reload: this.state.reload + 1,
			});
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	openMap(data: Model.Location) {
		window.open(`https://maps.google.com?q=${data.lat},${data.lon}&ll=${data.lat},${data.lon}&z=8`);
	}

	render() {
		return (
			<ExtendedList
				{...this.props}
				title="Locations"
				description="Use this list to manage all locations that will be used to fetch posts. You can enable or disable
			them according to your preferences."
				searchPlaceholder="Enter a city name to search after..."
				loadData={LocationService.getLocations}
				renderHeader={
					<div className="row align-items-center">
						<div className="col-3">City / Area</div>
						<div className="col text-center">Last fetched</div>
						<div className="col-2 d-flex justify-content-center">Status</div>
						<div className="col-2 d-flex flex-row justify-content-end">Options</div>
					</div>
				}
				renderItem={(
					value: Model.Location,
					index: number,
					updateEnabledCount: (enabledCount: number) => void
				) => {
					return (
						<ExtendedListItem
							key={index}
							data={value}
							itemIcon="bi-geo-alt-fill"
							columns={(data: Model.Location) => {
								return [
									<div className="col text-center fst-italic text-secondary" key="0">
										<Tooltip
											title={
												data.fetched_at
													? intlFormat(createLocalDate(data.fetched_at), {
															year: "numeric",
															month: "long",
															day: "numeric",
															hour: "numeric",
															minute: "2-digit",
													  })
													: ""
											}
										>
											<span>
												{data.fetched_at
													? formatDistanceToNowStrict(createLocalDate(data.fetched_at), {
															addSuffix: true,
													  })
													: "never"}
											</span>
										</Tooltip>
									</div>,
								];
							}}
							options={[
								{
									title: "Open in Maps",
									icon: "bi-map",
									onClick: () => this.openMap(value),
								},
							]}
							enable={LocationService.enable}
							disable={LocationService.disable}
							updateEnabledCount={updateEnabledCount}
						/>
					);
				}}
			/>
		);
	}
}

export default withRouter(LocationList);
