import { Popover as BsPopover } from "bootstrap";
import React, { useEffect, useRef } from "react";

export const Popover = (p: { children: JSX.Element; title: string, content: string }) => {
	const childRef = useRef(undefined as unknown as Element);

	useEffect(() => {
		const pCustomClass = "customClass";
		const t = new BsPopover(childRef.current, {
			title: p.title,
            content: p.content,
            html: true,
			placement: "top",
			customClass: `${pCustomClass} shadow-lg`,
            template: BsPopover.Default.template.replaceAll("popover-header", "popover-header bg-dark bg-gradient text-light")
		});

        // With the following eventListener handle click outside of the popover to dismiss it
        // Based on https://stackoverflow.com/a/72597451/7072249
		childRef.current.addEventListener("shown.bs.popover", () => {
			let element = document.querySelector("." + pCustomClass);

			if (!element) return;

			element.setAttribute("tabindex", "-1");

			element.addEventListener("focusout", async (e) => {
				if (!element?.contains((e as any).relatedTarget)) {
					// Hide the popover when focused out
					t.hide();
				}
			});

			// Finally, focus the parent .popover element
			(element as any).focus();
		});

		// Once closed, remove the focus event
		childRef.current.addEventListener("hide.bs.popover", () => {
			let element = document.querySelector("." + pCustomClass);
			if (!element) return;
			element.removeEventListener("focusout", () => {});
		});

		return () => t.dispose();
	}, [p.title, p.content]);

	return React.cloneElement(p.children, { ref: childRef });
};
