import React, { Component } from "react";
import * as Model from "../../model";
import { showModal } from "./Modal";
import UserService from "../../services/UserService";
import { createBase64PNGImage, setImageTemplate, defaultImageConfig, downloadImage } from "./ImageRender";
import { Tooltip } from "./Tooltip";
import { showToast, ToastType } from "./Toast";
import AuthService from "../../services/AuthService";
import PostService from "../../services/PostService";
import { sleep } from "../../common/sleepHelper";

let randomNumberToReinitModalComponent: number = 0;

export const showCreateImageModal = (post: Model.Post, user?: Model.User, afterDelete?: (id: number) => void) => {
	user = user ?? AuthService.getCurrentUser();
	if (!user) {
		showToast(ToastType.Error, "Failed to open modal");
		return;
	}

	showModal(
		`Export image`,
		<ImageModal post={post} user={user!} key={randomNumberToReinitModalComponent++} editConfig={false} />,
		"Download",
		async (startLoading, endLoading, hideModal) => {
			let base64 = (document.getElementById("image-data") as HTMLImageElement).src;

			await downloadImage(base64, startLoading, () => {});

			if (base64) {
				if (await PostService.delete(post.id)) {
					afterDelete?.(post.id);
				}
			}

			endLoading();
		},
		true,
		{
			infoButton: {
				emoji: "bi-emoji-smile",
				content:
					"Add emoji with native tools:<br/>Windows: <i class='bi bi-windows'></i> + Period (.)<br/>macOS: Ctrl + Cmd + Space",
			},
		}
	);
};

export const showEditImageConfigModal = (user: Model.User) => {
	showModal(
		`Set image configuration for ${user.username}`,
		<ImageModal user={user} key={randomNumberToReinitModalComponent++} editConfig={true} />,
		"Save",
		async (startLoading, endLoading, hideModal) => {
			startLoading();

			let imageConfig = JSON.parse(
				(document.getElementById("createImageModal-config") as HTMLInputElement).value
			) as Model.ImageConfig;

			const background = (document.getElementById("createImageModal-background") as HTMLInputElement).files?.[0];
			const font = (document.getElementById("createImageModal-font") as HTMLInputElement).files?.[0];

			if (background || font) {
				const uploadResponse = await UserService.uploadFiles(user.id, { background: background, font: font });
				if (uploadResponse?.image_config === undefined) {
					endLoading();
					return;
				}

				imageConfig.background = uploadResponse.image_config.background;
				imageConfig.font = uploadResponse.image_config.font;
			}

			if (await UserService.update(user.id, { imageConfig: imageConfig })) {
				hideModal();
				showToast(ToastType.Success, "Image configuration saved successfully");
			}
			endLoading();
		},
		true
	);
};

interface ImageModalProps {
	post?: Model.Post;
	user: Model.User;
	editConfig: boolean;
}

interface ImageModalState {
	imageConfig: Model.ImageConfig;
	size: "9:16" | "4:5" | "1:1";
	color: string;
	background?: string;
	font?: string;
	fontSize?: number;
	message?: string;

	users: Model.User[];
	user?: Model.User;
	nameInput: string;
	mounting: boolean;
	creatingImage: boolean;
	imageData: string;
}

export class ImageModal extends Component<ImageModalProps, ImageModalState> {
	readonly examplePost: Model.Post = {
		id: 1,
		location_id: 1,
		channel_id: 1,
		kpi: 45,
		kpi_performance: 0.72123,
		created_at: "string",
		post_id: "string",
		post_created_at: "string",
		post_location: "Berlin",
		post_message:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
		post_color: "A867E9",
		post_channel: "Main",
		post_votes: 2,
		post_comments: 2,
		post_pins: 2,
		post_shares: 2,
	};

	constructor(props: ImageModalProps) {
		super(props);

		this.state = {
			imageConfig: defaultImageConfig,
			size: "4:5",
			color: "A867E9",
			fontSize: defaultImageConfig.fontSize,
			message: (props.post ?? this.examplePost).post_message,

			users: [],
			nameInput: defaultImageConfig.name,
			mounting: true,
			creatingImage: false,
			imageData: "",
		};
	}

	async componentDidMount() {
		this.initImage();
	}

	async initImage() {
		this.setState({
			creatingImage: true,
		});

		if (!this.props.editConfig && this.props.user.is_admin && this.state.users.length === 0) {
			this.setState({
				users: (await UserService.getUsers())?.items ?? [],
			});
		}

		const imageConfig = await UserService.imageConfig((this.state.user ?? this.props.user).id);
		if (imageConfig) {
			this.setState({
				color: (this.props.editConfig ? defaultImageConfig : imageConfig).colors[0],
				imageConfig: { ...defaultImageConfig, ...imageConfig },
				nameInput: imageConfig.name ?? defaultImageConfig.name,
			});
		} else {
			this.setState({
				color: defaultImageConfig.colors[0],
				imageConfig: { ...defaultImageConfig },
				nameInput: defaultImageConfig.name,
			});
		}
		this.createImage();
	}

	async createImage() {
		this.setState({
			creatingImage: true,
		});

		const base64Image = await createBase64PNGImage();

		// Hacky solution to pass imageConfig to showCreateImageModal onSubmit call
		if (document.getElementById("createImageModal-config")) {
			(document.getElementById("createImageModal-config") as HTMLInputElement).value = JSON.stringify(
				this.state.imageConfig
			);
		}

		this.setState({
			mounting: false,
			creatingImage: false,
			imageData: base64Image,
		});
	}

	handleBackgroundRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			creatingImage: true,
		});

		const file = event.target.files![0];
		const base64 = await this.convertBase64(file);

		this.setState({
			background: base64,
		});
		this.createImage();
	};

	handleFontRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			creatingImage: true,
		});

		const file = event.target.files![0];
		const base64 = await this.convertBase64(file);

		document.head.insertAdjacentHTML(
			"beforeend",
			"<style>\
          @font-face {\
              font-family: 'tempFont';\
              src: url(data:font/truetype;charset=utf-8;base64," +
				base64.replace("data:application/octet-stream;base64,", "") +
				")format('truetype');\
              font-weight: normal;\
              font-style: normal;\
          }\
          </style>"
		);

		this.setState({
			font: "tempFont",
		});
		this.createImage();
	};

	convertBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result as string);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	render() {
		setImageTemplate(
			this.props.post ?? this.examplePost,
			this.state.imageConfig,
			this.state.size,
			this.state.color,
			this.state.background,
			this.state.font,
			this.state.fontSize,
			this.state.message
		);

		return (
			<div className="d-flex flex-row">
				<div className="flex-column d-flex flex-grow-1">
					{this.props.user.is_admin && this.state.users.length > 0 && (
						<div className="me-0 me-lg-3 mb-2">
							<div className="input-group input-group-sm">
								<Tooltip title="Select a user's image config.">
									<span className="input-group-text" id="basic-addon3">
										<i className="bi bi-person-fill"></i>
									</span>
								</Tooltip>
								<select
									className="form-select form-select-sm"
									id="createImageModal-size"
									aria-label=".form-select-sm example"
									value={this.state.user?.id}
									onChange={async (e) => {
										this.setState({
											creatingImage: true,
											user: this.state.users.find((x) => x.id === parseInt(e.target.value)),
										});

										// Make sure, state is changed
										await sleep(200);

										this.initImage();
									}}
								>
									{this.state.users.map((user) => {
										return <option value={user.id}>{user.username}</option>;
									})}
								</select>
							</div>
						</div>
					)}

					<div className="me-0 me-lg-3 mb-2">
						<div className="input-group input-group-sm">
							<Tooltip title="Set output format.">
								<span className="input-group-text" id="basic-addon3">
									<i className="bi bi-aspect-ratio"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="createImageModal-size"
								aria-label=".form-select-sm example"
								value={this.state.size}
								onChange={(e) => {
									this.setState({
										size: e.target.value as any,
									});
									this.createImage();
								}}
							>
								<option value="4:5">4:5</option>
								<option value="9:16">9:16</option>
								<option value="1:1">1:1</option>
							</select>
						</div>
					</div>

					{!this.props.editConfig && (
						<div className="me-0 me-lg-3 mb-2">
							<div className="input-group input-group-sm">
								<Tooltip title="Set font size.">
									<span className="input-group-text" id="basic-addon3">
										<i className="bi bi-fonts"></i>
									</span>
								</Tooltip>
								<div className="form-control form-control-sm d-flex">
									<input
										type="range"
										className="form-range"
										style={{ height: undefined }}
										min="25"
										max="75"
										step="5"
										id="createImageModal-fontSize"
										value={this.state.fontSize}
										onChange={(e) => {
											this.setState({
												fontSize: e.target.value as any,
											});
											this.createImage();
										}}
									/>
								</div>
							</div>
						</div>
					)}

					<div className="me-0 me-lg-3 mb-0 d-flex justify-content-center">
						{(this.props.editConfig || !this.state.imageConfig.background) &&
							(this.props.editConfig ? defaultImageConfig : this.state.imageConfig).colors.map(
								(value, index) => (
									<button
										type="button"
										className={`btn ${index === 0 ? "" : "ms-2"}`}
										style={{
											backgroundColor: `#${value}`,
											width: "30px",
											height: "30px",
											borderRadius: "15px",
											position: "relative",
											opacity: 1, // this.state.imageConfig.colors.includes(value) ? 1 : 0.5,
										}}
										onClick={() => {
											if (this.props.editConfig) {
												const colors = this.state.imageConfig.colors.includes(value)
													? this.state.imageConfig.colors.filter((x) => x !== value)
													: [...this.state.imageConfig.colors, value];
												this.setState({
													imageConfig: {
														...this.state.imageConfig,
														colors: colors,
													},
												});
											}
											this.setState({ color: value });

											this.createImage();
										}}
										key={index}
									>
										{this.props.editConfig && this.state.imageConfig.colors.includes(value) && (
											<i className="bi bi-check-square-fill color-selected"></i>
										)}
									</button>
								)
							)}
					</div>

					<hr className="border border-primary border-1 me-0 me-lg-3"></hr>

					{this.props.editConfig && this.renderEditConfig()}

					{!this.props.editConfig && (
						<div className="flex-grow-1 me-0 me-lg-3 mb-1">
							<textarea
								className="form-control form-control-sm"
								style={{ width: "100%", marginTop: "5px", height: "100%" }}
								id="createImageModal-message"
								placeholder="Content..."
								aria-label="Content"
								aria-describedby="basic-addon2"
								value={this.state.message}
								onChange={(e) => {
									this.setState({ message: e.target.value });
									this.createImage();
								}}
							/>
						</div>
					)}
				</div>

				<div className="position-relative flex-column" style={{ aspectRatio: 0.8, height: "400px" }}>
					<img
						src={this.state.imageData}
						className={this.state.creatingImage ? "opacity-25" : "opacity-100"}
						id="image-data"
						style={{ width: "100%", height: "100%", objectFit: "contain" }}
						alt=" "
					/>

					<div
						className={`position-absolute top-0 bottom-0 w-100 d-flex align-items-center justify-content-center ${
							this.state.creatingImage ? "opacity-100" : "opacity-0"
						}`}
						id="image-data-spinner"
					>
						<div
							className="spinner-border text-secondary"
							style={{ width: "3rem", height: "3rem" }}
							role="status"
						>
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>

				{this.state.mounting && (
					<div
						className={`position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center bg-white`}
						style={{ zIndex: 2 }}
						id="image-data-spinner"
					>
						<div
							className="spinner-border text-secondary"
							style={{ width: "3rem", height: "3rem" }}
							role="status"
						>
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				)}
			</div>
		);
	}

	renderEditConfig() {
		return (
			<div>
				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define if location will be displayed or not.">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-geo-alt-fill"></i>
							</span>
						</Tooltip>
						<select
							className="form-select form-select-sm"
							id="createImageModal-locationPosition"
							aria-label=".form-select-sm example"
							value={this.state.imageConfig.location ? "show" : "hide"}
							onChange={(e) => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										location: e.target.value === "show",
									},
								});
								this.createImage();
							}}
						>
							<option value="show">Show location</option>
							<option value="hide">Hide location</option>
						</select>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define position of location (will be ignored if Show location is disabled).">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-geo-alt-fill"></i>
							</span>
						</Tooltip>
						<select
							className="form-select form-select-sm"
							id="createImageModal-locationPosition"
							aria-label=".form-select-sm example"
							value={this.state.imageConfig.locationPosition}
							onChange={(e) => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										locationPosition: e.target.value as any,
									},
								});
								this.createImage();
							}}
						>
							<option value="top-left">Top-Left</option>
							<option value="top-center">Top-Center</option>
							<option value="top-right">Top-Right</option>
							<option value="bottom-left">Bottom-Left</option>
							<option value="bottom-center">Bottom-Center</option>
							<option value="bottom-right">Bottom-Right</option>
						</select>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define account name displayed next to the icon.">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-instagram"></i>
							</span>
						</Tooltip>
						<input
							type="text"
							className="form-control form-control-sm"
							id="createImageModal-name"
							placeholder="Name..."
							aria-label="Name"
							aria-describedby="basic-addon2"
							value={this.state.nameInput}
							onChange={(e) => this.setState({ nameInput: e.target.value })}
							onKeyDown={async (e: React.KeyboardEvent) => {
								if (e.key === "Enter") {
									this.setState({
										imageConfig: {
											...this.state.imageConfig,
											name: this.state.nameInput,
										},
									});
									this.createImage();
								}
							}}
						/>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define position of name.">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-instagram"></i>
							</span>
						</Tooltip>
						<select
							className="form-select form-select-sm"
							id="createImageModal-namePosition"
							aria-label=".form-select-sm example"
							value={this.state.imageConfig.namePosition}
							onChange={(e) => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										namePosition: e.target.value as any,
									},
								});
								this.createImage();
							}}
						>
							<option value="top-left">Top-Left</option>
							<option value="top-center">Top-Center</option>
							<option value="top-right">Top-Right</option>
							<option value="bottom-left">Bottom-Left</option>
							<option value="bottom-center">Bottom-Center</option>
							<option value="bottom-right">Bottom-Right</option>
						</select>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define text alignment of main content.">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-body-text"></i>
							</span>
						</Tooltip>
						<select
							className="form-select form-select-sm"
							id="createImageModal-textAlign"
							aria-label=".form-select-sm example"
							value={this.state.imageConfig.textAlign}
							onChange={(e) => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										textAlign: e.target.value as any,
									},
								});
								this.createImage();
							}}
						>
							<option value="left">Left</option>
							<option value="center">Center</option>
							<option value="right">Right</option>
							<option value="justify">Justify</option>
						</select>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm">
						<Tooltip title="Define text color.">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-body-text"></i>
							</span>
						</Tooltip>
						<select
							className="form-select form-select-sm"
							id="createImageModal-color"
							aria-label=".form-select-sm example"
							value={this.state.imageConfig.color}
							onChange={(e) => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										color: e.target.value as any,
									},
								});
								this.createImage();
							}}
						>
							<option value="white">White</option>
							<option value="black">Black</option>
						</select>
					</div>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm w-100 d-flex">
						<Tooltip title="Upload a background image. This will override the selected colors.">
							<label className="btn btn-secondary mt-0 flex-grow-1" htmlFor="createImageModal-background">
								{this.state.background || this.state.imageConfig.background
									? "Image file selected"
									: "Select background image"}
							</label>
						</Tooltip>
						<button
							type="button"
							className="btn btn-outline-danger btn-sm"
							disabled={!this.state.background && !this.state.imageConfig.background}
							onClick={() => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										background: undefined,
									},
									background: undefined,
								});
								this.createImage();
							}}
						>
							<i className="bi bi-trash3"></i>
						</button>
					</div>
					<input
						type="file"
						id="createImageModal-background"
						className="d-none"
						accept=".jpg, .jpeg, .png"
						onChange={(e) => this.handleBackgroundRead(e)}
					/>
				</div>

				<div className="me-0 me-lg-3 mb-2">
					<div className="input-group input-group-sm w-100 d-flex">
						<Tooltip title="Upload a font file in ttf format.">
							<label className="btn btn-secondary mt-0 flex-grow-1" htmlFor="createImageModal-font">
								{this.state.font || this.state.imageConfig.font
									? "Font file selected"
									: "Select font file"}
							</label>
						</Tooltip>
						<button
							type="button"
							className="btn btn-outline-danger btn-sm"
							disabled={!this.state.font && !this.state.imageConfig.font}
							onClick={() => {
								this.setState({
									imageConfig: {
										...this.state.imageConfig,
										font: undefined,
									},
									font: undefined,
								});
								this.createImage();
							}}
						>
							<i className="bi bi-trash3"></i>
						</button>
					</div>
					<input
						type="file"
						id="createImageModal-font"
						className="d-none"
						accept=".ttf"
						onChange={(e) => this.handleFontRead(e)}
					/>
				</div>

				<input id="createImageModal-config" type="hidden" className="d-none" />
			</div>
		);
	}
}
