import { createSearchParams } from "react-router-dom";
import apiService from "./APIService";
import * as Model from "../model";

interface GetChannelsResponseData {
	message: string;
	items: Model.Channel[];
	enabled_count: number;
	pagination: Model.Pagination;
}

class ChannelService {
	getChannels(page?: number, params?: { name?: string }): Promise<GetChannelsResponseData | undefined> {
		const searchParams = createSearchParams(params);
		const query = Array.from(searchParams).length > 0 ? `?${searchParams}` : "";

		return apiService
			.getApiInstance()
			.get(
				apiService.getURL() +
					"/channel/list" +
					(typeof page === "number" && page > 1 ? `/${page}` : "") +
					query,
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
                response.data.items = response.data.channels;
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	enable(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/channel/${id.toString()}/enable`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	disable(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/channel/${id.toString()}/disable`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	add(name: string): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/channel/add`, { name }, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	delete(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/channel/${id.toString()}/delete`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}
}

export default new ChannelService();
