import React, { Component } from "react";
import * as Model from "../../model";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { showModal } from "../common/Modal";
import { showToast, ToastType } from "../common/Toast";
import { Tooltip } from "../common/Tooltip";

let randomNumberToReinitModalComponent: number = 0;

export const showUserEditModal = (user: Model.User, channels: Model.Channel[], update: () => void) => {
	showModal(
		`Edit ${user.username}`,
		<UserEditModal user={user} channels={channels} key={randomNumberToReinitModalComponent++} />,
		"Save",
		async (startLoading, endLoading, hideModal) => {
			startLoading();

			const password = (document.getElementById("userEditModal-password") as HTMLInputElement).value;
			if (password) {
				const cpassword = (document.getElementById("userEditModal-cpassword") as HTMLInputElement).value;

				if (password !== cpassword || !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.exec(password)) {
					showToast(ToastType.Error, "Passwords do not match or do not fulfill the requirements.");

					endLoading();
					return;
				}

				if (!(await AuthService.setPassword(user.id, { password: password }))) {
					endLoading();
					return;
				}
			}

			if (!user.is_admin) {
				var selected: number[] = [];
				channels.forEach((value) => {
					if ((document.getElementById(`userEditModal-channel-${value.id}`) as HTMLInputElement).checked) {
						selected.push(value.id);
					}
				});
	
				if (await UserService.update(user.id, { channels: selected })) {
					update();
					hideModal();
				}
			} else {
				hideModal();
			}

			endLoading();
		},
		true
	);
};

interface UserEditModalProps {
	user: Model.User;
	channels: Model.Channel[];
}

export class UserEditModal extends Component<UserEditModalProps, any> {
	render() {
		return (
			<div>
				<h5>Password</h5>
				<div className="d-flex mb-4">
					<div className="input-group me-1">
						<span className="input-group-text">Password</span>
						<input
							type="password"
							className="form-control"
							aria-label="Password"
							id="userEditModal-password"
						/>
						<Tooltip title="Required are 8-32 characters, one lowercase letter, one uppercase letter and one digit">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-info-circle"></i>
							</span>
						</Tooltip>
					</div>

					<div className="input-group ms-1">
						<span className="input-group-text">Confirm</span>
						<input
							type="password"
							className="form-control"
							aria-label="Confirm Password"
							id="userEditModal-cpassword"
						/>
						<Tooltip title="The same password just to make sure there are no mistakes in your memory...">
							<span className="input-group-text" id="basic-addon3">
								<i className="bi bi-info-circle"></i>
							</span>
						</Tooltip>
					</div>
				</div>

				{!this.props.user.is_admin && <h5>Channels</h5>}
				{!this.props.user.is_admin && (
					<div className="d-flex flex-column align-items-start">
						{this.props.channels.map((value, index) => {
							return (
								<div className="form-check" key={index}>
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										id={`userEditModal-channel-${value.id}`}
										defaultChecked={
											this.props.user.channels.find((x) => x === value.id) ? true : false
										}
									/>
									<label className="form-check-label" htmlFor={`userEditModal-channel-${value.id}`}>
										{value.name}
									</label>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
