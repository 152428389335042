import { Component } from "react";
import * as Model from "../../model";

import { RouterProps, withRouter } from "../../common/withRouter";
import PostService, { GetPostsParams } from "../../services/PostService";
import { setMetadata } from "../../common/metadataHelper";
import PostListItem from "./PostListItem";
import { Tooltip } from "../common/Tooltip";
import { isEqual } from "../../common/equalityHelper";
import { formatISO9075 } from "date-fns";

interface PostListState {
	items: Model.Post[];
	pagination?: Model.Pagination;

	loading: boolean;
	params?: GetPostsParams;
}

class PostList extends Component<RouterProps, PostListState> {
	readonly paramIds: { id: string; elementType: "input" | "select"; valueType: "number" | "string"; default: any }[] =
		[
			{ id: "minAge", elementType: "select", valueType: "number", default: "" },
			{ id: "maxAge", elementType: "select", valueType: "number", default: "" },
			{ id: "location", elementType: "input", valueType: "string", default: "" },
			{ id: "content", elementType: "input", valueType: "string", default: "" },
			{ id: "commentCount", elementType: "select", valueType: "number", default: "" },
			{ id: "deleted", elementType: "select", valueType: "string", default: "false" },
			{ id: "order", elementType: "select", valueType: "string", default: "kpi" },
		];

	constructor(props: RouterProps) {
		super(props);

		var params: { [key: string]: any } = {};
		this.paramIds.map((x) => (params[x.id] = x.default));

		Object.keys(params).forEach(function (key) {
			if (typeof params[key] === "undefined") {
				delete params[key];
			}
		});

		this.state = {
			items: [],
			loading: false,

			params: params,
		};

		this.loadMore = this.loadMore.bind(this);
	}

	componentDidMount() {
		setMetadata({ title: "Posts" });
		window.addEventListener("scroll", this.loadMore);

		this.resetSearch();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.loadMore);
	}

	loadMore() {
		if (
			!this.state.loading &&
			(!this.state.pagination || this.state.pagination.currentPage < this.state.pagination.pageCount) &&
			window.innerHeight + document.documentElement.scrollTop + 100 >= document.scrollingElement!.scrollHeight
		) {
			console.log("load more on page " + this.state.pagination!.currentPage);
			this.loadData(this.state.pagination ? this.state.pagination!.currentPage + 1 : undefined);
		}
	}

	getSearchParams(): GetPostsParams {
		const getValue = (
			id: string,
			elementType: "input" | "select",
			valueType: "number" | "string"
		): number | string | undefined => {
			const htmlElement = document.getElementById(`postList-filter-${id}`);
			const value =
				elementType === "input"
					? (htmlElement as HTMLInputElement).value
					: (htmlElement as HTMLSelectElement).value;

			if (valueType === "number") {
				if (value.length > 0) return parseInt(value);
				return undefined;
			} else if (valueType === "string") {
				return value;
			}

			return undefined;
		};

		var params: { [key: string]: any } = {};
		this.paramIds.map(
			(paramId) => (params[paramId.id] = getValue(paramId.id, paramId.elementType, paramId.valueType))
		);

		Object.keys(params).forEach(function (key) {
			if (typeof params[key] === "undefined") {
				delete params[key];
			}
		});

		return params as GetPostsParams;
	}

	search() {
		this.setState({
			items: [],
			pagination: undefined,
		});

		this.loadData(undefined, this.getSearchParams());
	}

	resetSearch() {
		this.paramIds.forEach((paramId) => {
			const htmlElement = document.getElementById(`postList-filter-${paramId.id}`);
			switch (paramId.elementType) {
				case "input":
					(htmlElement as HTMLInputElement).value = paramId.default;
					break;
				case "select":
					(htmlElement as HTMLSelectElement).value = paramId.default;
					break;
			}
		});

		this.search();
	}

	async loadData(page?: number, params?: GetPostsParams) {
		const usedPage = params ? undefined : page;
		const usedParams = params ?? this.state.params;

		this.setState({
			loading: true,
		});

		const posts = await PostService.getPosts(usedPage, usedParams);
		const items = (params ? [] : this.state.items).concat(posts?.items ?? []);

		if (!isEqual(usedParams, this.getSearchParams())) {
			// Params have been changed, so ignore this response
			return;
		}

		this.setState({
			items: items.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
			pagination: posts?.pagination,

			loading: false,
			params: params ?? this.state.params,
		});
	}

	render() {
		return (
			<div className="postList-filter col mx-auto">
				{/* Filter */}
				<div className="mx-auto mb-3 d-flex flex-lg-row flex-column" style={{ maxWidth: "1000px" }}>
					<div className="me-0 me-lg-3 mb-3 mb-lg-0 flex-grow-1">
						<div className="input-group input-group-sm">
							<span className="input-group-text" id="basic-addon1">
								<i className="bi bi-geo-alt-fill"></i>
							</span>
							<input
								type="text"
								className="form-control form-control-sm"
								id="postList-filter-location"
								placeholder="Location..."
								aria-label="Location"
								aria-describedby="basic-addon1"
								onKeyDown={async (e: React.KeyboardEvent) => {
									if (e.key === "Enter") {
										this.search();
									}
								}}
							/>
						</div>
					</div>

					<div className="me-0 mb-3 mb-lg-0 flex-grow-1">
						<div className="input-group input-group-sm">
							<Tooltip
								title={
									'<div>Filter posts for keywords separated by a space.<br/><br/>Options:<br>- Use quotation marks to search for combinations (e.g. <em>"netflix and chill"</em>).<br/>- Use an asterisk as a placeholder to extend a word (e.g. <em>net*</em> to find all posts starting with "net").<br/>- Use a minus sign to exclude a word (e.g. <em>-coffee</em>).<br/>All options can be combined.<br/><br/>Additionally you can use the channel flag to search for contents within one or many channels (e.g. <em>channel=*stories).</div>'
								}
							>
								<span className="input-group-text" id="basic-addon2">
									<i className="bi bi-body-text"></i>
								</span>
							</Tooltip>
							<input
								type="text"
								className="form-control form-control-sm"
								id="postList-filter-content"
								placeholder="Content..."
								aria-label="Content"
								aria-describedby="basic-addon2"
								onKeyDown={async (e: React.KeyboardEvent) => {
									if (e.key === "Enter") {
										this.search();
									}
								}}
							/>
						</div>
					</div>
				</div>

				<div
					className="mx-auto mb-3 d-flex flex-lg-row flex-column justify-content-lg-center"
					style={{ maxWidth: "1000px" }}
				>
					<div className="me-0 me-lg-3 mb-3 mb-lg-0">
						<div className="input-group input-group-sm">
							<Tooltip title="Filter based on a min age">
								<span className="input-group-text" id="basic-addon0">
									<i className="bi bi-clock-history"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="postList-filter-minAge"
								aria-label=".form-select-sm example"
								onChange={() => this.search()}
							>
								{["3", "7", "14", "30", "60", "90", ""].map((value: string, index: number) => (
									<option value={value} key={index}>
										{value ? `min ${value} days` : "All"}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="me-0 me-lg-3 mb-3 mb-lg-0">
						<div className="input-group input-group-sm">
							<Tooltip title="Filter based on a max age">
								<span className="input-group-text" id="basic-addon0">
									<i className="bi bi-clock"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="postList-filter-maxAge"
								aria-label=".form-select-sm example"
								onChange={() => this.search()}
							>
								{["3", "7", "14", "30", "60", "90", ""].map((value: string, index: number) => (
									<option value={value} key={index}>
										{value ? `max ${value} days` : "All"}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="me-0 me-lg-3 mb-3 mb-lg-0">
						<div className="input-group input-group-sm">
							<Tooltip title="Select if deleted content shall be shown">
								<span className="input-group-text" id="basic-addon3">
									<i className="bi bi-trash3"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="postList-filter-deleted"
								aria-label=".form-select-sm example"
								onChange={() => this.search()}
							>
								<option value="false">Hide</option>
								<option value="true">Show</option>
								<option value="only">Only</option>
							</select>
						</div>
					</div>

					<div className="me-0 me-lg-3 mb-3 mb-lg-0">
						<div className="input-group input-group-sm">
							<Tooltip title="Filter based on comment count">
								<span className="input-group-text" id="basic-addon4">
									<i className="bi bi-chat-dots"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="postList-filter-commentCount"
								aria-label=".form-select-sm example"
								onChange={() => this.search()}
							>
								<option value="100">&gt; 100</option>
								<option value="300">&gt; 300</option>
								<option value="500">&gt; 500</option>
								<option value="">All</option>
							</select>
						</div>
					</div>

					<div className="me-0 me-lg-3 mb-3 mb-lg-0">
						<div className="input-group input-group-sm">
							<Tooltip title="Define how the content shall be sorted">
								<span className="input-group-text" id="basic-addon5">
									<i className="bi bi-sort-numeric-down-alt"></i>
								</span>
							</Tooltip>
							<select
								className="form-select form-select-sm"
								id="postList-filter-order"
								aria-label=".form-select-sm example"
								onChange={() => this.search()}
							>
								<option value="kpi">KPI</option>
								<option value="votes">Votes</option>
								<option value="comments">Comments</option>
								<option value="created_at">Date</option>
							</select>
						</div>
					</div>

					<button
						type="button"
						className="btn btn-outline-secondary btn-sm d-flex"
						disabled={this.state.loading}
						onClick={() => this.resetSearch()}
					>
						<i className="bi bi-x-circle me-2"></i>
						Reset all
					</button>
				</div>

				<hr className="border border-primary border-1"></hr>

				{/* List */}
				<div className="container">
					<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3">
						{this.state.loading || this.state.items.length > 0 ? (
							this.state.items.map((value, index) => {
								return (
									<PostListItem
										data={value}
										afterDelete={(id) => {
											if (this.state.params?.deleted === "true") {
												// If showing also deleted items, change icon
												this.setState({
													items: this.state.items.map((x) => {
														if (x.id === id)
															x.deleted_at = x.deleted_at
																? undefined
																: formatISO9075(new Date());
														return x;
													}),
												});
											} else {
												// Else delete from current list
												this.setState({
													items: this.state.items.filter((x) => x.id !== id),
												});
											}
										}}
										key={index}
									/>
								);
							})
						) : (
							<div className="w-100 d-flex flex-column align-items-center">
								<i className="bi bi-exclamation-triangle fs-1"></i>
								<span>No posts found for your filter options...</span>
							</div>
						)}
					</div>

					<div
						className={`d-flex justify-content-center my-3 ${this.state.loading ? "visible" : "invisible"}`}
					>
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PostList);
