import axios from "axios";
import apiService from "./APIService";
import * as Model from "../model";
import { showToast, ToastType } from "../components/common/Toast";

class AuthService {
	login(username: string, password: string): Promise<Model.User | undefined> {
		return axios
			.postForm(
				apiService.getURL() + "/auth/login",
				{
					username,
					password,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				if (response.data.access_token) {
					localStorage.setItem("user", JSON.stringify(response.data));
				}

				return response.data.user;
			})
			.catch(apiService.errorHandler);
	}

	logout(): Promise<void> {
		return axios
			.post(apiService.getURL() + "/auth/logout", undefined, { withCredentials: true })
			.then((response) => {
				localStorage.removeItem("user");
				return;
			})
			.catch(apiService.errorHandler);
	}

	register(username: string): Promise<any> {
		return axios
			.post(
				apiService.getURL() + "/auth/register",
				{
					username,
				},
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
				showToast(ToastType.Success, `${response.data.message} with password ${response.data.password}`);
				return true;
			})
			.catch(apiService.errorHandler);
	}

	setPassword(id: number, params: { password: string }): Promise<any> {
		return axios
			.post(
				`${apiService.getURL()}/auth/setPassword`,
				{ userId: id, password: params.password },
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
				showToast(ToastType.Success, response.data.message);
				return true;
			})
			.catch(apiService.errorHandler);
	}

	getCurrentUser(): Model.User | undefined {
		let json = localStorage.getItem("user");
		return json ? JSON.parse(json!).user : undefined;
	}
}

export default new AuthService();
