import { showToast, ToastType } from "../components/common/Toast";
import apiService from "./APIService";

class ProxyService {
	testProxy(url: string, type: string): Promise<void> {
		const formData = new FormData();
		formData.append("url", url);
		formData.append("type", type);

		return apiService
			.getApiInstance()
			.post(apiService.getURL() + "/proxy/test", formData, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				showToast(ToastType.Success, response.data.message);
				return;
			})
			.catch(apiService.errorHandler);
	}
}

export default new ProxyService();
