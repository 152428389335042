import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import { RoutePaths } from "./common/withRouter";
import * as Model from "./model";
import AuthService from "./services/AuthService";

import Menu from "./components/common/Menu";
import Login from "./components/auth/Login";
import PostList from "./components/post/PostList";
import LocationList from "./components/location/LocationList";
import ChannelList from "./components/channel/ChannelList";
import UserList from "./components/user/UserList";
import LogList from "./components/log/LogList";
import { Tooltip } from "./components/common/Tooltip";
import Profile from "./components/user/Profile";

interface AppState {
	isMounted: boolean;
	currentUser?: Model.User;
}

class App extends Component<any, AppState> {
	readonly routes: { path: string; element: any; isPrivate?: boolean; isAdmin?: boolean }[] = [
		{ path: RoutePaths.Home, element: <PostList />, isPrivate: true },
		{ path: RoutePaths.Login, element: <Login updateUser={(user?: Model.User) => this.updateUser(user)} /> },
		{ path: RoutePaths.Locations, element: <LocationList />, isPrivate: true, isAdmin: true },
		{ path: RoutePaths.Channels, element: <ChannelList />, isPrivate: true, isAdmin: true },
		{ path: RoutePaths.Users, element: <UserList />, isPrivate: true, isAdmin: true },
		{ path: RoutePaths.Logs, element: <LogList />, isPrivate: true, isAdmin: true },
		{ path: RoutePaths.Profile, element: <Profile />, isPrivate: true },
	];

	constructor(props: any) {
		super(props);

		this.state = {
			isMounted: false,
			currentUser: undefined,
		};
	}

	componentDidMount() {
		document.body.classList.add("bg-light");

		const user = AuthService.getCurrentUser();

		this.setState({
			isMounted: true,
			currentUser: user,
		});
	}

	updateUser(user?: Model.User) {
		this.setState({
			currentUser: user,
		});
	}

	async logout(): Promise<boolean> {
		this.setState({
			isMounted: false,
		});

		await AuthService.logout();

		this.setState({
			currentUser: undefined,
			isMounted: true,
		});

		return true;
	}

	render() {
		const { currentUser, isMounted } = this.state;

		if (!isMounted) {
			return (
				<div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light">
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			);
		}

		return (
			<div>
				<Menu currentUser={currentUser} logout={async () => this.logout()} />

				<div className="container mt-3 pb-4 justify-content-center">
					{/* Toast placeholder */}
					<div
						id="toast-stack-container"
						className="toast-container position-fixed top-0 start-50 translate-middle-x p-3"
					>
						<div
							id="success-toast"
							className="toast mb-3"
							role="alert"
							aria-live="assertive"
							aria-atomic="true"
						>
							<div className="alert alert-success" role="alert">
								<i className="bi bi-check-circle-fill me-2"></i>
								<span></span>
							</div>
						</div>

						<div
							id="error-toast"
							className="toast mb-3"
							role="alert"
							aria-live="assertive"
							aria-atomic="true"
						>
							<div className="alert alert-danger" role="alert">
								<i className="bi bi-exclamation-triangle-fill me-2"></i>
								<span></span>
							</div>
						</div>
					</div>

					{/* Modal placeholder */}
					<div
						className="modal fade"
						id="modal"
						tabIndex={-1}
						aria-labelledby="modalTitle"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="modal-title fs-5" id="modalTitle">
										.
									</h1>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div className="modal-body" id="modalMessage"></div>
								<div className="modal-footer">
									<Tooltip title=".">
										<button
											className="btn btn-outline-info d-none"
											type="button"
											id="modalInfoButton"
										>
											<i className=""></i>
										</button>
									</Tooltip>
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										id="modalCancelButton"
									>
										Cancel
									</button>
									<button type="button" className="btn btn-primary" id="modalSubmitButton">
										<span
											className="spinner-border spinner-border-sm me-2 d-none"
											id="modalSubmitButtonSpinner"
										></span>
										<span id="modalSubmitButtonTitle">Save</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* Image render placeholder */}
					<div
						className="position-absolute"
						id="image-template-container"
						style={{ top: "-9000px", left: "-9000px" }}
					></div>

					<Routes>
						{this.routes.map((value: any, index: number) => {
							return (
								<Route
									path={value.path}
									element={
										value.isPrivate
											? this.withUser(value.element, currentUser, value.isAdmin)
											: value.element
									}
									key={index}
								/>
							);
						})}
					</Routes>
				</div>
			</div>
		);
	}

	withUser(children: JSX.Element, user?: Model.User, isAdmin?: boolean) {
		if (!user) {
			// TODO?: pass location state={{ from: location }}
			return <Navigate to={RoutePaths.Login} />;
		}

		if (isAdmin && !user?.is_admin) {
			return <Navigate to={RoutePaths.Home} />;
		}

		return children;
	}
}

export default App;
