import axios, { AxiosInstance } from "axios";
import { showToast, ToastType } from "../components/common/Toast";

class APIService {
	private axiosApiInstance: AxiosInstance;
    errorHandler = (error: any) => {
        if (error.response && error.response.data.message) {
            showToast(ToastType.Error, error.response.data.message);
        } else {
            showToast(ToastType.Error, "Something went wrong. Reload the page and try again.");
        }
    }

	constructor() {
		this.axiosApiInstance = axios.create();
		this.setInterceptors();
	}

	private setInterceptors() {
		// Response interceptor for API calls
		this.axiosApiInstance.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				const originalRequest = error.config;
				if (error.response.status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;
					const access_token = (await this.refreshToken()).access_token;
					originalRequest.headers["Authorization"] = "Bearer " + access_token;
					return this.axiosApiInstance(originalRequest);
				}
				return Promise.reject(error);
			}
		);
	}

	private refreshToken() {
		return axios.post(this.getURL() + "/auth/refreshToken", undefined, { withCredentials: true }).then((response) => {
			if (response.data.access_token) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}

			return response.data;
		});
	}

    getApiInstance(): AxiosInstance {
        return this.axiosApiInstance;
    }

	getURL() {
		if (process.env.NODE_ENV === 'development') return "http://localhost:8080";
		return "https://content-generator.api.yoodel.co";
	}

	authHeader() {
		const json = localStorage.getItem("user");
		const user = json ? JSON.parse(json) : undefined;

		if (user && user.access_token) {
			return { Authorization: "Bearer " + user.access_token };
		} else {
			return {};
		}
	}
}

export default new APIService();
