import React, { Component } from "react";
import * as Model from "../../model";

import { RouterProps } from "../../common/withRouter";
import Pagination from "./Pagination";

export interface ExtendedListLoadingResponse<T> {
	message: string;
	items: T[];
	enabled_count?: number;
	pagination: Model.Pagination;
}

interface ExtendedListProps<T> extends RouterProps {
	title: string;
	description: string;

	enabledTitle?: string;
	hasSearch?: boolean;
	searchPlaceholder?: string;
	addPlaceholder?: string;

	loadData: (page?: number, params?: { name?: string }) => Promise<ExtendedListLoadingResponse<T> | undefined>;
	addItem?: (name: string) => Promise<Model.Response.EnableDisableDeleteResponseData | undefined>;

	renderHeader: JSX.Element;
	renderItem: (
		value: T,
		index: number,
		updateEnabledCount: (enabledCount: number) => void,
		deleteItem: (id: number) => void
	) => JSX.Element;
}

interface ExtendedListState<T> {
	loading?: boolean;
	items: T[];
	enabledCount?: number;
	pagination?: Model.Pagination;

	name: string;

	addName: string;
	isAdding: boolean;
}

class ExtendedList<T extends { id: number }> extends Component<ExtendedListProps<T>, ExtendedListState<T>> {
	constructor(props: ExtendedListProps<T>) {
		super(props);

		this.state = {
			loading: true,
			items: [],

			name: this.props.search.params.get("name") ?? "",
			addName: "",
			isAdding: false,
		};
	}

	async componentDidMount() {
		this.loadList();
	}

	componentDidUpdate(prevProps: Readonly<ExtendedListProps<T>>, prevState: Readonly<ExtendedListState<T>>): void {
		if (this.props.search.params !== prevProps.search.params) {
			this.loadList();
			this.setState({ name: this.props.search.params.get("name") ?? "" });
		}
	}

	async loadList() {
		this.setState({
			loading: true,
		});

		const page = parseInt(this.props.search.params.get("page") ?? "");
		let params: any = {};
		if (this.props.search.params.has("name")) params["name"] = this.props.search.params.get("name");

		const data = await this.props.loadData(page, params);

		this.setState({
			loading: false,
			items: data?.items ?? [],
			enabledCount: data?.enabled_count,
			pagination: data?.pagination,
		});
	}

	async add() {
		this.setState({
			isAdding: true,
		});

		if (await this.props.addItem?.(this.state.addName)) {
			this.loadList();

			this.setState({
				addName: "",
			});
		}

		this.setState({
			isAdding: false,
		});
	}

	updateEnabledCount(enabledCount: number) {
		this.setState({
			enabledCount: enabledCount,
		});
	}

	deleteItem(id: number) {
		this.setState({
			items: this.state.items.filter((value) => value.id !== id),
		});
	}

	render() {
		return (
			<div className="col-md-8 mx-auto">
				<div className="d-flex align-items-end justify-content-between">
					<h1 className="mt-5 mb-4">{this.props.title}</h1>
					{this.state.enabledCount !== undefined && (
						<div className="input-group mt-5 mb-4 w-auto">
							<span className="input-group-text" id="basic-addon2">
								{this.props.enabledTitle ?? "Enabled"}
							</span>
							<span
								className="form-control readonly-input"
								placeholder="Username"
								aria-label="Username"
								aria-describedby="basic-addon2"
							>
								{this.state.enabledCount}
							</span>
						</div>
					)}
				</div>
				<p className="fw-light">{this.props.description}</p>

				{this.state.loading && (
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				)}

				{!this.state.loading && this.renderList()}
			</div>
		);
	}

	renderList() {
		return (
			<div>
				{this.props.hasSearch !== false && (
					<div>
						<div className="input-group mb-3">
							<span className="input-group-text" id="basic-addon1">
								<i className="bi bi-search me-2"></i> Name
							</span>
							<input
								type="text"
								className="form-control"
								placeholder={this.props.searchPlaceholder ?? "Search for content..."}
								value={this.state.name}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									this.setState({ name: e.target.value })
								}
								aria-label="Name of a location"
								aria-describedby="basic-addon1"
								onKeyDown={(e: React.KeyboardEvent) => {
									if (e.key === "Enter") {
										let params = new URLSearchParams(this.props.search.params);
										params.set("name", this.state.name);
										params.delete("page");
										this.props.search.set(params);
									}
								}}
							/>
							<button
								className="btn btn-outline-secondary"
								type="button"
								onClick={() => {
									this.setState({ name: "" });

									let params = new URLSearchParams(this.props.search.params);
									params.delete("name");
									params.delete("page");
									this.props.search.set(params);
								}}
							>
								<i className="bi bi-trash3"></i>
							</button>
						</div>
					</div>
				)}

				<ul className="list-group">
					<li className="list-group-item container bg-dark bg-gradient text-light">
						{this.props.renderHeader}
					</li>
					{this.state.items.map((value: T, index: number) =>
						this.props.renderItem(
							value,
							index,
							(enabledCount: number) => this.updateEnabledCount(enabledCount),
							(id: number) => this.deleteItem(id)
						)
					)}
				</ul>

				{this.props.addItem && (
					<div className="input-group my-3">
						<span className="input-group-text" id="basic-addon1">
							<i className="bi bi-plus-circle-fill me-2"></i> Add
						</span>
						{this.state.isAdding && (
							<div
								className="position-absolute end-0 h-100 d-flex align-items-center display"
								style={{ borderRadius: "50%", zIndex: 1000 }}
							>
								<span className="spinner-border spinner-border-sm text-secondary me-2"></span>
							</div>
						)}
						<input
							type="text"
							className="form-control"
							placeholder={this.props.addPlaceholder ?? "Enter name of new item..."}
							value={this.state.addName}
							disabled={this.state.isAdding}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								this.setState({ addName: e.target.value })
							}
							aria-label="Name of a channel"
							aria-describedby="basic-addon1"
							onKeyDown={async (e: React.KeyboardEvent) => {
								if (e.key === "Enter") {
									this.add();
								}
							}}
						/>
					</div>
				)}

				<nav aria-label="Page navigation">
					<Pagination data={this.state.pagination} {...this.props} />
				</nav>
			</div>
		);
	}
}

export default ExtendedList;
