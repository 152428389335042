import React, { Component } from "react";
import * as Model from "../../model";
import LogService from "../../services/LogService";

import { RouterProps, withRouter } from "../../common/withRouter";
import ExtendedList, { ExtendedListLoadingResponse } from "../common/ExtendedList";
import ExtendedListItem from "../common/ExtendedListItem";
import { setMetadata } from "../../common/metadataHelper";
import { Tooltip } from "../common/Tooltip";
import { formatDistanceToNowStrict, intlFormat } from "date-fns";
import { createLocalDate } from "../../common/datetimeHelper";
import { Popover } from "../common/Popover";
import ChannelService from "../../services/ChannelService";

interface LogListState {
	channels: Model.Channel[];
	reload: number;
}

class LogList extends Component<RouterProps, LogListState> {
	interval?: NodeJS.Timer;

	constructor(props: RouterProps) {
		super(props);

		this.state = {
			channels: [],
			reload: 0,
		};
	}

	componentDidMount(): void {
		setMetadata({ title: "Logs" });

		this.interval = setInterval(() => {
			this.setState({
				reload: this.state.reload + 1,
			});
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async loadData(page?: number): Promise<ExtendedListLoadingResponse<Model.Log> | undefined> {
		let channels = await ChannelService.getChannels();
		if (!channels) return channels;

		this.setState({
			channels: channels!.items,
		});
		return channels && (await LogService.getLogs(page));
	}

	render() {
		return (
			<ExtendedList
				{...this.props}
				title="Logs"
				description="Check all background requests that have been done to fetch new posts."
				hasSearch={false}
				loadData={(page) => this.loadData(page)}
				renderHeader={
					<div className="row align-items-center">
						<div className="col-3">Location</div>
						<div className="col text-center">New/Posts</div>
						<div className="col-2 d-flex justify-content-center">Time</div>
						<div className="col d-flex justify-content-center">Duration</div>
						<div className="col d-flex flex-row justify-content-end">Details</div>
					</div>
				}
				renderItem={(
					value: Model.Log,
					index: number,
					updateEnabledCount: (enabledCount: number) => void,
					deleteItem: (id: number) => void
				) => {
					return (
						<ExtendedListItem
							key={index}
							data={value}
							itemIcon={value.error ? "bi-exclamation" : "bi-check"}
							itemTitle={(data) => data.location_name}
							columns={(data: Model.Log) => {
								return [
									<div className="col text-center fst-italic text-secondary" key="0">
										<span>{`${data.new_post_count}/${data.post_count}`}</span>
									</div>,
									<div className="col-2 text-center fst-italic text-secondary" key="1">
										<Tooltip
											title={intlFormat(createLocalDate(data.started_at), {
												year: "numeric",
												month: "long",
												day: "numeric",
												hour: "numeric",
												minute: "2-digit",
											})}
										>
											<span>
												{formatDistanceToNowStrict(createLocalDate(data.started_at), {
													addSuffix: true,
												})}
											</span>
										</Tooltip>
									</div>,
									<div className="col text-center fst-italic text-secondary" key="2">
										<span>{`${data.duration / 1000}s`}</span>
									</div>,
									<div className="col text-end fst-italic text-secondary" key="3">
										{data.channel_data.length > 0 && (
											<Popover
												title="Channels fetched"
												content={`${this.state.channels
													.filter(
														(value) =>
															data.channel_data.findIndex((x) => x === value.id) !== -1
													)
													.map((x) => x.name)
													.join(", ")}`}
											>
												<button className="btn btn-outline-primary btn-sm me-1" type="button">
													<i className="bi bi-broadcast"></i>
												</button>
											</Popover>
										)}

										{data.warning && (
											<Popover title="Warning message" content={`${data.warning}`}>
												<button className="btn btn-outline-warning btn-sm me-1" type="button">
													<i className="bi bi-bug"></i>
												</button>
											</Popover>
										)}

										{data.error && (
											<Popover title="Error message" content={`${data.error}`}>
												<button className="btn btn-outline-danger btn-sm me-1" type="button">
													<i className="bi bi-bug"></i>
												</button>
											</Popover>
										)}
									</div>,
								];
							}}
						/>
					);
				}}
			/>
		);
	}
}

export default withRouter(LogList);
