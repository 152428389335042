import apiService from "./APIService";
import * as Model from "../model";

interface GetLogsResponseData {
	message: string;
	items: Model.Log[];
	enabled_count: number;
	pagination: Model.Pagination;
}

class LogService {
	getLogs(page?: number): Promise<GetLogsResponseData | undefined> {
		return apiService
			.getApiInstance()
			.get(apiService.getURL() + "/log/list" + (typeof page === "number" && page > 1 ? `/${page}` : ""), {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				response.data.items = response.data.logs;
				return response.data;
			})
			.catch(apiService.errorHandler);
	}
}

export default new LogService();
