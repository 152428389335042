import React from "react";
import { Link } from "react-router-dom";
import { RoutePaths, RouterProps, withRouter } from "../../common/withRouter";
import * as Model from "../../model";

interface MenuProps extends RouterProps {
	currentUser?: Model.User;
	logout?: () => Promise<boolean>;
}

class Menu extends React.Component<MenuProps, any> {
	render() {
		if (!this.props.currentUser) {
			return undefined;
		}

		return (
			<nav className="navbar sticky-top navbar-expand navbar-dark bg-black bg-gradient px-4 d-flex justify-content-between">
				<Link to={"/"} className="navbar-brand">
					Content Generator
				</Link>
				<div className="navbar-nav mr-auto">
					<li className="nav-item">
						<Link
							to={RoutePaths.Home}
							className={`nav-link ${this.props.location.pathname === RoutePaths.Home && "active"}`}
						>
							<i className="bi bi-chat-text me-1"></i>
							<span className="d-none d-md-inline-block">Posts</span>
						</Link>
					</li>

					{this.props.currentUser?.is_admin && (
						<li className="nav-item">
							<Link
								to={RoutePaths.Locations}
								className={`nav-link ${
									this.props.location.pathname.startsWith(RoutePaths.Locations) && "active"
								}`}
							>
								<i className="bi bi-geo-alt-fill me-1"></i>
								<span className="d-none d-md-inline-block">Locations</span>
							</Link>
						</li>
					)}

					{this.props.currentUser?.is_admin && (
						<li className="nav-item">
							<Link
								to={RoutePaths.Channels}
								className={`nav-link ${
									this.props.location.pathname.startsWith(RoutePaths.Channels) && "active"
								}`}
							>
								<i className="bi bi-broadcast me-1"></i>
								<span className="d-none d-md-inline-block">Channels</span>
							</Link>
						</li>
					)}

					{this.props.currentUser?.is_admin && false && (
						<li className="nav-item">
							<Link
								to={RoutePaths.Accounts}
								className={`nav-link ${
									this.props.location.pathname.startsWith(RoutePaths.Accounts) && "active"
								}`}
							>
								<i className="bi bi-person-gear me-1"></i>
								<span className="d-none d-md-inline-block">Accounts</span>
							</Link>
						</li>
					)}

					{this.props.currentUser?.is_admin && (
						<li className="nav-item">
							<Link
								to={RoutePaths.Users}
								className={`nav-link ${
									this.props.location.pathname.startsWith(RoutePaths.Users) && "active"
								}`}
							>
								<i className="bi bi-people-fill me-1"></i>
								<span className="d-none d-md-inline-block">Users</span>
							</Link>
						</li>
					)}

					{this.props.currentUser?.is_admin && (
						<li className="nav-item">
							<Link
								to={RoutePaths.Logs}
								className={`nav-link ${
									this.props.location.pathname.startsWith(RoutePaths.Logs) && "active"
								}`}
							>
								<i className="bi bi-list-check me-1"></i>
								<span className="d-none d-md-inline-block">Logs</span>
							</Link>
						</li>
					)}

					<li className="nav-item">
						<Link
							to={RoutePaths.Profile}
							className={`nav-link ${
								this.props.location.pathname.startsWith(RoutePaths.Profile) && "active"
							}`}
						>
							<i className="bi bi-person-circle me-1"></i>
							<span className="d-none d-md-inline-block">Profile</span>
						</Link>
					</li>

					<li className="nav-item">
						{/* eslint-disable-next-line */}
						<a
							href="#"
							className="nav-link"
							onClick={async () => {
								if (await this.props.logout?.()) {
									this.props.navigate(RoutePaths.Login);
								}
							}}
						>
							<i className="bi bi-box-arrow-right me-1"></i>
							<span className="d-none d-md-inline-block">Logout</span>
						</a>
					</li>
				</div>
			</nav>
		);
	}
}

export default withRouter(Menu);
