import React, { Component } from "react";
import * as Model from "../../model";
import { showToast, ToastType } from "./Toast";
import { showModal } from "./Modal";
import { Tooltip } from "./Tooltip";

interface ExtendedListItemProps<T> {
	data: T;
	itemIcon: string;
	itemTitle?: (data: T) => string;
	columns?: (data: T) => JSX.Element[];
	options?: (
		| {
				title: string;
				className?: string;
				icon: string;
				onClick: (startLoading: () => void, endLoading: () => void) => void;
		  }
		| undefined
	)[];

	enable?: (id: number) => Promise<Model.Response.EnableDisableDeleteResponseData | undefined>;
	disable?: (id: number) => Promise<Model.Response.EnableDisableDeleteResponseData | undefined>;
	delete?: (id: number) => Promise<Model.Response.EnableDisableDeleteResponseData | undefined>;

	updateEnabledCount?: (enabledCount: number) => void;
	deleteItem?: (id: number) => void;
}

interface ExtendedListItemState {
	loading?: boolean;
}

class ExtendedListItem<T extends { id: number; name?: string; enabled?: boolean }> extends Component<
	ExtendedListItemProps<T>,
	ExtendedListItemState
> {
	constructor(props: ExtendedListItemProps<T>) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	async setEnabled(enabled: boolean) {
		this.setState({
			loading: true,
		});

		let response = await (enabled
			? this.props.enable!(this.props.data.id)
			: this.props.disable!(this.props.data.id));

		if (response) {
			this.props.data.enabled = enabled;
			this.props.updateEnabledCount?.(response.enabled_count);

			this.setState({
				loading: false,
			});

			showToast(ToastType.Success, response.message);
		}
	}

	async delete() {
		this.setState({
			loading: true,
		});

		let response = await this.props.delete!(this.props.data.id);

		if (response) {
			this.props.updateEnabledCount?.(response.enabled_count);
			this.props.deleteItem?.(this.props.data.id);

			this.setState({
				loading: false,
			});

			showToast(ToastType.Success, response.message);
		}
	}

	render() {
		let options: (
			| {
					title: string;
					className?: string;
					icon: string;
					onClick: (startLoading: () => void, endLoading: () => void) => void;
			  }
			| undefined
		)[] = [];
		options.push(...(this.props.options ?? []));
		if (this.props.delete) {
			options.push({
				title: "Delete",
				className: "btn-outline-danger",
				icon: "bi-trash3",
				onClick: () => {
					showModal("Delete", "Are you sure you want to delete this element?", "Delete", () => this.delete());
				},
			});
		}

		return (
			<li className="list-group-item container">
				{this.state.loading && (
					<div className="overlay" id="loading">
						<div className="d-flex justify-content-center align-items-center h-100">
							<div className="spinner-border" role="status" />
						</div>
					</div>
				)}

				<div className="row align-items-center">
					<div className="col-3">
						<i className={`bi ${this.props.itemIcon} me-2`}></i>
						{this.props.itemTitle?.(this.props.data) ?? this.props.data.name}
					</div>
					{this.props.columns && this.props.columns(this.props.data)}
					{this.props.enable && this.props.disable && (
						<div className="col-2 d-flex justify-content-center">
							<Tooltip title={this.props.data.enabled ? "Click to disable" : "Click to enable"}>
								<button
									type="submit"
									className={`btn btn${
										this.props.data.enabled ? "" : "-outline"
									}-primary btn-block btn-sm`}
									disabled={this.state.loading}
									onClick={() => this.setEnabled(!this.props.data.enabled)}
								>
									{this.props.data.enabled ? "Enabled" : "Disabled"}
								</button>
							</Tooltip>
						</div>
					)}
					{(this.props.options || this.props.delete) && (
						<div className="col-2 d-flex flex-row justify-content-end">
							{options.map((value, index, array) => {
								if (!value) return undefined;

								return (
									<Tooltip title={value.title} key={index}>
										<button
											type="button"
											className={`btn ${value.className ?? "btn-outline-secondary"} btn-sm ${
												index - 1 !== array.length ? " ms-2" : ""
											}`}
											aria-label={value.title}
											onClick={() => {
												value.onClick(
													() => {
														this.setState({ loading: true });
													},
													() => {
														this.setState({ loading: false });
													}
												);
											}}
										>
											<i className={`bi ${value.icon}`}></i>
										</button>
									</Tooltip>
								);
							})}
						</div>
					)}
				</div>
			</li>
		);
	}
}

export default ExtendedListItem;
