import { Component } from "react";
import { createLocalDate } from "../../common/datetimeHelper";
import * as Model from "../../model";
import { Tooltip } from "../common/Tooltip";
import { formatDistanceToNowStrict, intlFormat } from "date-fns";
import PostService from "../../services/PostService";
import { Popover } from "../common/Popover";
import { showModal } from "../common/Modal";
import { showCreateImageModal } from "../common/CreateImageModal";
import { createBase64PNGImage, defaultImageConfig, downloadImage, setImageTemplate } from "../common/ImageRender";
import UserService from "../../services/UserService";
import { randomNumber } from "../../common/numberHelper";

interface PostListItemProps {
	data: Model.Post;
	afterDelete: (id: number) => void;
}

interface PostListItemState {
	isDownloadLoading: boolean;
	isDeleteLoading: boolean;
}

class PostListItem extends Component<PostListItemProps, PostListItemState> {
	constructor(props: PostListItemProps) {
		super(props);

		this.state = {
			isDownloadLoading: false,
			isDeleteLoading: false,
		};
	}

	render() {
		return (
			<div className="col post">
				<div className="card h-100">
					<div
						className="card-header text-secondary d-flex justify-content-between bg-gradient text-light"
						style={{ backgroundColor: `#${this.props.data.post_color}` }}
					>
						<div>
							<span className="d-flex align-items-center">
								<i className="bi bi-geo-alt-fill"></i>
								{this.props.data.post_location}
							</span>
						</div>
						<div className="d-flex">
							<span className="d-flex align-items-center me-2">
								<i className="bi bi-chevron-up"></i>
								{this.props.data.post_votes}
							</span>
							<span className="d-flex align-items-center me-2">
								<i className="bi bi-chat-dots"></i>
								{this.props.data.post_comments}
							</span>
							<span className="d-flex align-items-center">
								<i className="bi bi-graph-up-arrow"></i>
								{`${Math.round(this.props.data.kpi_performance * 100)}%`}
							</span>
						</div>
					</div>
					<div className="card-body">
						<p className="card-text">{this.props.data.post_message}</p>
					</div>
					<div className="card-footer d-flex justify-content-between">
						<div className="details text-secondary fst-italic">
							<span className="d-flex align-items-center">
								<i className="bi bi-broadcast"></i>
								{this.props.data.post_channel}
							</span>
							<span className="d-flex align-items-center">
								<i className="bi bi-clock"></i>
								<Tooltip
									title={intlFormat(createLocalDate(this.props.data.post_created_at), {
										year: "numeric",
										month: "long",
										day: "numeric",
										hour: "numeric",
										minute: "2-digit",
									})}
								>
									<span>
										{formatDistanceToNowStrict(createLocalDate(this.props.data.post_created_at), {
											addSuffix: true,
										})}
									</span>
								</Tooltip>
							</span>
						</div>
						<div className="d-flex">
							<Tooltip title="Download image">
								<button
									className="btn btn-outline-primary btn-sm me-1"
									type="button"
									disabled={this.state.isDownloadLoading || this.state.isDeleteLoading}
									onClick={async () => {
										this.setState({ isDownloadLoading: true });

										const imageConfig = {
											...defaultImageConfig,
											...(await UserService.imageConfig()),
										};
										if (!imageConfig) {
											this.setState({ isDownloadLoading: false });
											return;
										}

										setImageTemplate(
											this.props.data,
											imageConfig,
											"4:5",
											imageConfig.colors[randomNumber(imageConfig.colors.length)],
											undefined,
											undefined,
											defaultImageConfig.fontSize
										);

										const base64 = await createBase64PNGImage();
										if (!base64) {
											this.setState({ isDownloadLoading: false });
											return;
										}

										await downloadImage(
											base64,
											() => {},
											() => {}
										);

										if (await PostService.delete(this.props.data.id)) {
											this.props.afterDelete(this.props.data.id);
										}

										this.setState({ isDownloadLoading: false });
									}}
								>
									{this.state.isDownloadLoading && (
										<span className="spinner-border spinner-border-sm me-2"></span>
									)}
									<i className="bi bi-download"></i>
								</button>
							</Tooltip>
							<Tooltip title="Create image">
								<button
									className="btn btn-outline-primary btn-sm me-1"
									type="button"
									disabled={this.state.isDownloadLoading || this.state.isDeleteLoading}
									onClick={() =>
										showCreateImageModal(this.props.data, undefined as any, this.props.afterDelete)
									}
								>
									<i className="bi bi-image-fill"></i>
								</button>
							</Tooltip>
							{this.props.data.post_id && (
								<Popover
									title="About this post"
									content={`Post ID: ${this.props.data.post_id}<br />Created at: ${this.props.data.post_created_at}<br />Added at: ${this.props.data.created_at}<br /><br /><a href="https://share.jodel.com/post?postId=${this.props.data.post_id}" target="_blank" class="btn btn-primary btn-sm me-1">View in browser</a>`}
								>
									<button
										className="btn btn-outline-primary btn-sm me-1"
										type="button"
										disabled={this.state.isDownloadLoading || this.state.isDeleteLoading}
									>
										<i className="bi bi-info-circle"></i>
									</button>
								</Popover>
							)}
							{!this.props.data.deleted_at && (
								<Tooltip title="Delete post">
									<button
										className="btn btn-outline-danger btn-sm"
										type="button"
										disabled={this.state.isDownloadLoading || this.state.isDeleteLoading}
										onClick={() =>
											showModal(
												"Delete",
												"Are you sure you want to delete this post?",
												"Delete",
												async (startLoading, endLoading, hideModal) => {
													hideModal();
													this.setState({ isDeleteLoading: true });
													if (await PostService.delete(this.props.data.id)) {
														this.props.afterDelete(this.props.data.id);
													}
													this.setState({ isDeleteLoading: false });
												},
												true
											)
										}
									>
										{this.state.isDeleteLoading && (
											<span className="spinner-border spinner-border-sm me-2"></span>
										)}
										<i className="bi bi-trash3"></i>
									</button>
								</Tooltip>
							)}
							{this.props.data.deleted_at && (
								<Tooltip title="Restore deleted">
									<button
										className="btn btn-outline-danger btn-sm"
										type="button"
										disabled={this.state.isDownloadLoading || this.state.isDeleteLoading}
										onClick={async () => {
											this.setState({ isDeleteLoading: true });
											if (await PostService.restore(this.props.data.id)) {
												this.props.afterDelete(this.props.data.id);
											}
											this.setState({ isDeleteLoading: false });
										}}
									>
										{this.state.isDeleteLoading && (
											<span className="spinner-border spinner-border-sm me-2"></span>
										)}
										<i className="bi bi-arrow-counterclockwise"></i>
									</button>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PostListItem;
