import React, { Component } from "react";
import * as Model from "../../model";
import ChannelService from "../../services/ChannelService";

import { RouterProps, withRouter } from "../../common/withRouter";
import ExtendedList from "../common/ExtendedList";
import ExtendedListItem from "../common/ExtendedListItem";
import { setMetadata } from "../../common/metadataHelper";

class ChannelList extends Component<RouterProps, any> {
	componentDidMount(): void {
		setMetadata({ title: "Channels" });
	}

	render() {
		return (
			<ExtendedList
				{...this.props}
				title="Channels"
				description="Use this list to manage all channels that will be used to fetch posts. You can enable or disable
			them according to your preferences as well as add new or delete current channels."
				loadData={ChannelService.getChannels}
				addItem={ChannelService.add}
				renderHeader={
					<div className="row align-items-center">
						<div className="col-3">Name</div>
						<div className="col text-center">Post count</div>
						<div className="col-2 d-flex justify-content-center">Status</div>
						<div className="col-2 d-flex flex-row justify-content-end">Options</div>
					</div>
				}
				renderItem={(
					value: Model.Channel,
					index: number,
					updateEnabledCount: (enabledCount: number) => void,
					deleteItem: (id: number) => void
				) => {
					return (
						<ExtendedListItem
							key={index}
							data={value}
							itemIcon="bi-broadcast"
							columns={(data: Model.Channel) => {
								return [
									<div className="col text-center fst-italic text-secondary" key="0">
										<span>{data.post_count}</span>
									</div>,
								];
							}}
							enable={ChannelService.enable}
							disable={ChannelService.disable}
							delete={ChannelService.delete}
							updateEnabledCount={updateEnabledCount}
							deleteItem={deleteItem}
						/>
					);
				}}
			/>
		);
	}
}

export default withRouter(ChannelList);
