import React, { Component } from "react";
import * as Model from "../../model";
import UserService from "../../services/UserService";

import { RouterProps, withRouter } from "../../common/withRouter";
import ExtendedList, { ExtendedListLoadingResponse } from "../common/ExtendedList";
import ExtendedListItem from "../common/ExtendedListItem";
import AuthService from "../../services/AuthService";
import ChannelService from "../../services/ChannelService";
import { showUserEditModal } from "./UserEditModal";
import { setMetadata } from "../../common/metadataHelper";
import { showEditImageConfigModal } from "../common/CreateImageModal";

interface UserListState {
	channels: Model.Channel[];
}

class UserList extends Component<RouterProps, UserListState> {
	extendedList?: ExtendedList<Model.User>;

	constructor(props: RouterProps) {
		super(props);

		this.state = {
			channels: [],
		};
	}

	componentDidMount() {
		setMetadata({ title: "Users" });
	}

	async loadData(
		page?: number,
		params?: { name?: string }
	): Promise<ExtendedListLoadingResponse<Model.User> | undefined> {
		let channels = await ChannelService.getChannels();
		if (!channels) return channels;

		this.setState({
			channels: channels!.items,
		});
		return channels && (await UserService.getUsers(page, params));
	}

	render() {
		return (
			<ExtendedList
				{...this.props}
				ref={elem => this.extendedList = elem ?? undefined}
				title="Users"
				description="Use this list to manage all users in the portal. Here you can edit the channels a user is able to access as well as reset the user password. Finally you can create new users and delete current ones."
				enabledTitle="Users"
				loadData={(page, params) => this.loadData(page, params)}
				addItem={AuthService.register}
				renderHeader={
					<div className="row align-items-center">
						<div className="col-3">Username</div>
						<div className="col text-center">Channels</div>
						<div className="col-2 d-flex justify-content-center">Admin</div>
						<div className="col-2 d-flex flex-row justify-content-end">Options</div>
					</div>
				}
				renderItem={(
					value: Model.User,
					index: number,
					updateEnabledCount: (enabledCount: number) => void,
					deleteItem: (id: number) => void
				) => {
					return (
						<ExtendedListItem
							key={index}
							data={value}
							itemIcon="bi-person-fill"
							itemTitle={(data) => data.username}
							columns={(data: Model.User) => {
								return [
									<div className="col text-center fst-italic text-secondary" key="0">
										<span>
											{data.is_admin
												? "All channels"
												: this.state.channels
														.filter(
															(value) =>
																data.channels.findIndex((x) => x === value.id) !== -1
														)
														.map((x) => x.name)
														.join(", ")}
										</span>
									</div>,
									<div className="col-2 text-center" key="1">
										<span>{data.is_admin ? "yes" : ""}</span>
									</div>,
								];
							}}
							options={[
								{
									title: "Edit",
									icon: "bi-pencil-fill",
									onClick: () => {
										showUserEditModal(value, this.state.channels, () => this.extendedList?.loadList());
									},
								},
								{
									title: "Set image configuration",
									icon: "bi-image-fill",
									onClick: () => {
										showEditImageConfigModal(value);
									},
								},
							]}
							delete={UserService.delete}
							updateEnabledCount={updateEnabledCount}
							deleteItem={deleteItem}
						/>
					);
				}}
			/>
		);
	}
}

export default withRouter(UserList);
