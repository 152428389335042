import { renderToStaticMarkup } from "react-dom/server";
import * as htmlToImage from "html-to-image";
import { sleep } from "../../common/sleepHelper";
import * as Model from "../../model";
import APIService from "../../services/APIService";

const imageSizes = {
	"4:5": { width: "1080px", height: "1350px" },
	"9:16": { width: "1080px", height: "1920px" },
	"1:1": { width: "1080px", height: "1080px" },
};

export const defaultImageConfig: Model.ImageConfig = {
	colors: ["A867E9", "009D52", "338DF5", "E84C4C", "E35BB5", "27A899", "FA7E0A"],
	location: true,
	locationPosition: "bottom-left",
	name: "YOODEL.CO",
	namePosition: "bottom-right",
	textAlign: "left",
	color: "white",
	background: undefined,
	font: "",
	fontSize: 50,
};

export const setImageTemplate = (
	post: Model.Post,
	imageConfig: Model.ImageConfig,
	size: "9:16" | "4:5" | "1:1",
	color: string,
	background?: string,
	font?: string,
	fontSize?: number,
	message?: string
) => {
	if (!font && imageConfig.font) {
		const fontname = imageConfig.font.substring(imageConfig.font.lastIndexOf("/") + 1).replace(".ttf", "");
		font = fontname;

		if (!document.head.innerHTML.includes(imageConfig.font)) {
			document.head.insertAdjacentHTML(
				"beforeend",
				"<style>\
			  @font-face {\
				  font-family: '" +
					fontname +
					"';\
				  src: url('" +
					`${APIService.getURL()}/${imageConfig.font}` +
					"') format('truetype');\
				  font-weight: normal;\
				  font-style: normal;\
			  }\
			  </style>"
			);
		}
	}

	const innerHTML = (
		<div
			id="image-template"
			style={{
				width: imageSizes[size].width,
				height: imageSizes[size].height,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "0px",
				color: imageConfig.color,
				fontSize: `${fontSize}px`,
				textAlign: imageConfig.textAlign,
				fontFamily: font ? `'${font}'` : "'Lato-Regular'",
				backgroundColor: background ? undefined : `#${color}`,
				backgroundImage: background
					? `url(${background})`
					: imageConfig.background
					? `url(${APIService.getURL()}/${imageConfig.background})`
					: undefined,
				position: "relative",
			}}
		>
			<span style={{ margin: "100px", whiteSpace: "pre-wrap" }}>{message ?? post.post_message}</span>

			{imageConfig.location && (
				<div
					style={{
						...getStyle(imageConfig.locationPosition),
						fontSize: "30px",
						margin: "15px 60px 15px 60px",
					}}
				>
					<i className="bi bi-geo-alt-fill me-1"></i>
					{post.post_location}
				</div>
			)}

			{imageConfig.name && (
				<div
					style={{
						...getStyle(imageConfig.namePosition),
						fontSize: "30px",
						margin: "15px 60px 15px 60px",
					}}
				>
					<i className="bi bi-instagram me-2"></i>
					{imageConfig.name}
				</div>
			)}
		</div>
	);

	const templateContainer = document.getElementById("image-template-container") as HTMLElement;
	templateContainer.innerHTML = renderToStaticMarkup(innerHTML);
};

export const createBase64PNGImage = async () => {
	// Make sure the new content is rendered
	await sleep(200);

	const template = document.getElementById("image-template") as HTMLElement;
	if (!template) {
		return "";
	}

	const base64Image = await htmlToImage.toPng(template, {
		height: parseInt(template.style.height.replace("px", "")),
		width: parseInt(template.style.width.replace("px", "")),
	});

	return base64Image;
};

export const downloadImage = async (base64: string, startLoading: () => void, endLoading: () => void) => {
	startLoading();

	var a = document.createElement("a");
	a.href = base64;
	a.download = "image.png";
	a.click();

	endLoading();
};

const getStyle = (position: string) => {
	var style: React.CSSProperties = { position: "absolute", display: "flex", flexDirection: "row" };

	if (position.startsWith("top")) style.top = 0;
	else if (position.startsWith("bottom")) style.bottom = 0;

	if (position.endsWith("left")) style.left = 0;
	else if (position.endsWith("right")) style.right = 0;

	return style;
};
