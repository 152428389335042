import { Component } from "react";
import * as Model from "../../model";

import { RouterProps, withRouter } from "../../common/withRouter";
import AuthService from "../../services/AuthService";
import { setMetadata } from "../../common/metadataHelper";
import { Tooltip } from "../common/Tooltip";
import { showToast, ToastType } from "../common/Toast";
import ProxyService from "../../services/ProxyService";
import SettingService from "../../services/SettingService";

interface ProfileState {
	user?: Model.User;
	proxies: { url: string; type: string }[];

	loading: boolean;
	testingProxy: boolean;
}

class Profile extends Component<RouterProps, ProfileState> {
	readonly proxyCount = 15;

	constructor(props: RouterProps) {
		super(props);

		this.state = {
			user: AuthService.getCurrentUser(),
			proxies: [],

			loading: false,
			testingProxy: false,
		};
	}

	componentDidMount() {
		setMetadata({ title: "Profile" });

		if (this.state.user?.is_admin) {
			this.loadData();
		}
	}

	async loadData(): Promise<void> {
		this.setState({
			loading: true,
		});

		const setting = await SettingService.get("proxy");
		if (!setting || setting?.value === null) {
			this.setState({
				loading: false,
			});
			return;
		}

		this.setState({
			proxies: JSON.parse(setting.value!),
			loading: false,
		});
	}

	async updatePassword() {
		this.setState({
			loading: true,
		});

		const password = (document.getElementById("profile-password") as HTMLInputElement).value;
		if (password) {
			const cpassword = (document.getElementById("profile-cpassword") as HTMLInputElement).value;

			if (password !== cpassword || !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.exec(password)) {
				showToast(ToastType.Error, "Passwords do not match or do not fulfill the requirements.");

				this.setState({
					loading: false,
				});
				return;
			}

			if (!(await AuthService.setPassword(this.state.user!.id, { password: password }))) {
				this.setState({
					loading: false,
				});
				return;
			}
		} else {
			showToast(ToastType.Error, "Passwords are empty.");
		}

		this.setState({
			loading: false,
		});
	}

	isValidIPAddress(url: string): boolean {
		const ipRegex = /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{1,4})?$/;
		return ipRegex.test(url);
	}

	async testProxy(id: number) {
		const url = (document.getElementById(`profile-proxy-${id}-url`) as HTMLInputElement).value;
		const type = (document.getElementById(`profile-proxy-${id}-type`) as HTMLSelectElement).value;

		if (!this.isValidIPAddress(url)) {
			showToast(ToastType.Error, "Proxy IP address is not valid");
			return;
		}

		this.setState({
			testingProxy: true,
		});

		await ProxyService.testProxy(url, type);

		this.setState({
			testingProxy: false,
		});
	}

	resetProxy(id: number) {
		(document.getElementById(`profile-proxy-${id}-url`) as HTMLInputElement).value = "";
		(document.getElementById(`profile-proxy-${id}-type`) as HTMLSelectElement).value = "http";
	}

	async updateProxy() {
		let hasInvalidUrl = false;
		let proxies: { url: string; type: string }[] = [];

		Array.from({ length: this.proxyCount }, (v, i) => i).forEach((value) => {
			const url = (document.getElementById(`profile-proxy-${value}-url`) as HTMLInputElement).value;
			const type = (document.getElementById(`profile-proxy-${value}-type`) as HTMLSelectElement).value;

			if (!url) return;

			if (!this.isValidIPAddress(url)) hasInvalidUrl = true;
			else {
				proxies.push({
					url: url,
					type: type,
				});
			}
		});

		if (hasInvalidUrl) {
			showToast(ToastType.Error, "One of the proxy IP addresses is invalid");
			return;
		}

		this.setState({
			loading: true,
		});

		if (await SettingService.update("proxy", JSON.stringify(proxies))) {
			showToast(ToastType.Success, "Successfully updated proxies");
		}

		this.setState({
			proxies: proxies,
			loading: false,
		});
	}

	render() {
		return (
			<div className="col-md-8 mx-auto">
				<div className="d-flex align-items-end justify-content-between">
					<h1 className="mt-5 mb-4">{"Profile"}</h1>
				</div>
				<p className="fw-light">{`Hello ${this.state.user?.username}, here you can view and update your profile.`}</p>

				{this.state.loading && (
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				)}

				{!this.state.loading && this.renderSettings()}
			</div>
		);
	}

	renderSettings() {
		return (
			<div>
				<h5>Password</h5>
				<div className="mb-4">
					<div className="d-flex mb-2">
						<div className="input-group me-1">
							<span className="input-group-text">Password</span>
							<input
								type="password"
								className="form-control"
								aria-label="Password"
								id="profile-password"
							/>
							<Tooltip title="Required are 8-32 characters, one lowercase letter, one uppercase letter and one digit">
								<span className="input-group-text" id="basic-addon3">
									<i className="bi bi-info-circle"></i>
								</span>
							</Tooltip>
						</div>

						<div className="input-group ms-1">
							<span className="input-group-text">Confirm</span>
							<input
								type="password"
								className="form-control"
								aria-label="Confirm Password"
								id="profile-cpassword"
							/>
							<Tooltip title="The same password just to make sure there are no mistakes in your memory...">
								<span className="input-group-text" id="basic-addon3">
									<i className="bi bi-info-circle"></i>
								</span>
							</Tooltip>
						</div>
					</div>

					<button
						type="button"
						className={`btn btn-primary`}
						aria-label={"Update password"}
						onClick={() => this.updatePassword()}
					>
						Update password
					</button>
				</div>

				{this.state.user?.is_admin && <h5>Proxy</h5>}
				{this.state.user?.is_admin && (
					<p className="fw-light">
						Update the proxies used to fetch posts. Possible sources for free proxies are{" "}
						<a
							href="http://free-proxy.cz/en/proxylist/country/DE/http/ping/all"
							target="_blank"
							rel="noreferrer"
						>
							free-proxy.cz
						</a>{" "}
						or{" "}
						<a href="https://spys.one/free-proxy-list/DE/" target="_blank" rel="noreferrer">
							spys.one
						</a>
						.
					</p>
				)}
				{this.state.user?.is_admin && (
					<div>
						<div>
							{Array.from({ length: this.proxyCount }, (v, i) => i).map((value, index) => {
								const proxy = index < this.state.proxies.length ? this.state.proxies[index] : undefined;

								return (
									<div className="d-flex mb-2" key={index}>
										<div className="input-group me-2">
											<input
												type="text"
												className="form-control"
												aria-label="Proxy url"
												id={`profile-proxy-${value}-url`}
												defaultValue={proxy?.url}
											/>
											<Tooltip title="The IP address of a proxy that should be used. The proxies will be used in the order listed here. The input should be an IP address followed by the port, e.g. 192.168.0.1:1234.">
												<span className="input-group-text" id="basic-addon3">
													<i className="bi bi-info-circle"></i>
												</span>
											</Tooltip>
										</div>

										<div className="input-group me-2">
											<select
												className="form-select form-select-sm"
												id={`profile-proxy-${value}-type`}
												aria-label=".form-select-sm example"
												onChange={() => {}}
												defaultValue={proxy?.type}
											>
												<option value="http">HTTP</option>
												<option value="https">HTTPS</option>
												{/*<option value="socks4">SOCKS4</option>
												<option value="socks5">SOCKS5</option>*/}
											</select>
											<Tooltip title="The type of the proxy. Do not always trust the type in the proxy list but also try the other ones.">
												<span className="input-group-text" id="basic-addon3">
													<i className="bi bi-info-circle"></i>
												</span>
											</Tooltip>
										</div>

										<button
											className="btn btn-outline-primary me-2"
											type="button"
											disabled={this.state.testingProxy}
											onClick={() => this.testProxy(value)}
										>
											<i className="bi bi-play-fill"></i>
										</button>

										<button
											className="btn btn-outline-danger"
											type="button"
											onClick={() => this.resetProxy(value)}
										>
											<i className="bi bi-x-circle"></i>
										</button>
									</div>
								);
							})}
						</div>

						<button
							type="button"
							className={`btn btn-primary`}
							aria-label={"Update proxy settings"}
							onClick={() => this.updateProxy()}
						>
							Update proxy settings
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(Profile);
