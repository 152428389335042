import React from "react";
import {
	useLocation,
	useNavigate,
	useParams,
	Location,
	NavigateFunction,
	Params,
	useSearchParams,
	URLSearchParamsInit,
} from "react-router-dom";

export enum RoutePaths {
	Home = "/",
	Login = "/login",
	Profile = "/profile",
	Locations = "/locations",
	Channels = "/channels",
	Accounts = "/accounts",
	Users = "/users",
	Logs = "/logs"
}

export const withRouter = <Props extends RouterProps>(Component: React.ComponentType<Props>) => {
	return (props: Omit<Props, keyof RouterProps>) => {
		const location = useLocation();
		const params = useParams();
		const [searchParams, setSearchParams] = useSearchParams();
		const navigate = useNavigate();

		return (
			<Component
				{...(props as Props)}
				location={location}
				navigate={navigate}
				params={params}
				search={{ params: searchParams, set: setSearchParams }}
			/>
		);
	};
};

export interface RouterProps {
	location: Location;
	navigate: NavigateFunction;
	params: Readonly<Params<string>>;
	search: { params: URLSearchParams; set: (nextInit?: URLSearchParamsInit) => void };
}
