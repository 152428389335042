import React, { Component } from "react";
import { Formik } from "formik";
import AuthService from "../../services/AuthService";

import * as Model from "../../model";
import { RoutePaths, RouterProps, withRouter } from "../../common/withRouter";
import { setMetadata } from "../../common/metadataHelper";

interface LoginProps extends RouterProps {
	updateUser?: (user?: Model.User) => void;
}

class Login extends Component<LoginProps, any> {
	componentDidMount(): void {
		setMetadata({ title: "Login" });
	}

	async handleLogin(values: { username: string; password: string }) {
		let user = await AuthService.login(values.username, values.password);

		if (user) {
			this.props.updateUser?.(user);
			this.props.navigate(RoutePaths.Home);
		}
	}

	validate(values: { username: string; password: string }): { username?: string; password?: string } {
		let errors: { username?: string; password?: string } = {};
		if (!values.username) {
			errors.username = "Required";
		} else if (values.username.length < 3) {
			errors.username = "Username must be 3 characters long.";
		} else if (values.username.length > 50) {
			errors.username = "Username must be max 50 characters long.";
		}

		const passwordRegex = /(?=.*[0-9])/;
		if (!values.password) {
			errors.password = "Required";
		} else if (values.password.length < 8) {
			errors.password = "Password must be 8 characters long.";
		} else if (!passwordRegex.test(values.password)) {
			errors.password = "Invalid password. Must contain one number.";
		}

		return errors;
	}

	render() {
		return (
			<div className="col-md-12 login">
				<div className="card card-container">
					<div className="profile-img-card">
						<i className="bi bi-person-fill"></i>
					</div>

					<Formik
						initialValues={{ username: "", password: "" }}
						validate={this.validate}
						onSubmit={async (values, { setSubmitting }) => {
							await this.handleLogin(values);
							setSubmitting(false);
						}}
					>
						{(props) => {
							const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } =
								props;

							return (
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<label htmlFor="username">Username</label>
										<input
											id="username"
											className={`form-control ${
												errors.username && touched.username && " is-invalid"
											}`}
											name="username"
											type="text"
											placeholder="Enter your username"
											value={values.username}
											onChange={handleChange}
											onBlur={handleBlur}
											disabled={isSubmitting}
										/>
										{errors.username && touched.username && (
											<div className="invalid-feedback">{errors.username}</div>
										)}
									</div>

									<div className="form-group">
										<label htmlFor="password">Password</label>
										<input
											id="password"
											className={`form-control ${
												errors.password && touched.password && " is-invalid"
											}`}
											name="password"
											type="password"
											placeholder="Enter your password"
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											disabled={isSubmitting}
										/>
										{errors.password && touched.password && (
											<div className="invalid-feedback">{errors.password}</div>
										)}
									</div>

									<div className="form-group">
										<button
											type="submit"
											className="btn btn-primary btn-block"
											disabled={isSubmitting}
										>
											{isSubmitting && (
												<span className="spinner-border spinner-border-sm me-2"></span>
											)}
											<span>Login</span>
										</button>
									</div>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
		);
	}
}

export default withRouter(Login);
