import apiService from "./APIService";
import * as Model from "../model";

class SettingService {
	get(key: string): Promise<Model.Setting | undefined> {
		return apiService
			.getApiInstance()
			.get(`${apiService.getURL()}/setting/${key}`, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data.setting;
			})
			.catch(apiService.errorHandler);
	}

	update(key: string, value?: string): Promise<Model.Setting | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/setting/${key}/update`, value, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data.setting;
			})
			.catch(apiService.errorHandler);
	}
}

export default new SettingService();
