import { createSearchParams } from "react-router-dom";
import apiService from "./APIService";
import * as Model from "../model";

export interface GetPostsParams {
	minAge?: number;
	maxAge?: number;
	location?: string;
	content?: string;
	commentCount?: number;
	deleted?: "true" | "false" | "only";
	order?: "kpi" | "votes" | "comments" | "date";
}

interface GetPostsResponseData {
	message: string;
	items: Model.Post[];
	pagination: Model.Pagination;
}

class PostService {
	getPosts(page?: number, params?: GetPostsParams): Promise<GetPostsResponseData | undefined> {
		const searchParams = createSearchParams(params as any);
		const query = Array.from(searchParams).length > 0 ? `?${searchParams}` : "";

		return apiService
			.getApiInstance()
			.get(
				apiService.getURL() + "/post/list" + (typeof page === "number" && page > 1 ? `/${page}` : "") + query,
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
				response.data.items = response.data.posts;
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	restore(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/post/${id.toString()}/restore`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	delete(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/post/${id.toString()}/delete`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}
}

export default new PostService();
