import { createSearchParams } from "react-router-dom";
import apiService from "./APIService";
import * as Model from "../model";

interface GetLocationsResponseData {
	message: string;
	items: Model.Location[];
	enabled_count: number;
	pagination: Model.Pagination;
}

class LocationService {
	getLocations(page?: number, params?: { name?: string }): Promise<GetLocationsResponseData | undefined> {
		const searchParams = createSearchParams(params);
		const query = Array.from(searchParams).length > 0 ? `?${searchParams}` : "";

		return apiService
			.getApiInstance()
			.get(
				apiService.getURL() +
					"/location/list" +
					(typeof page === "number" && page > 1 ? `/${page}` : "") +
					query,
				{
					headers: apiService.authHeader(),
				}
			)
			.then((response) => {
                response.data.items = response.data.locations;
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	enable(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/location/${id.toString()}/enable`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}

	disable(id: number): Promise<Model.Response.EnableDisableDeleteResponseData | undefined> {
		return apiService
			.getApiInstance()
			.post(`${apiService.getURL()}/location/${id.toString()}/disable`, undefined, {
				headers: apiService.authHeader(),
			})
			.then((response) => {
				return response.data;
			})
			.catch(apiService.errorHandler);
	}
}

export default new LocationService();
