import { ReactElement } from "react";
import { Modal } from "bootstrap";
import ReactDOM from "react-dom/client";
import { setTooltipContent } from "./Tooltip";

let modalMessageRoot: any = undefined;
export const showModal = (
	title: string,
	content: ReactElement | string,
	submitTitle: string,
	onSubmit:
		| ((startLoading: () => void, endLoading: () => void, hideModal: () => void) => Promise<void>)
		| (() => void),
	awaitOnSubmit?: boolean,
	options?: {
		infoButton?: {
			emoji: string;
			content: string;
		};
	}
) => {
	const modalElement = document.getElementById("modal") as HTMLElement;
	(modalElement.querySelector("#modalTitle") as HTMLElement).innerText = title;
	if ((content as ReactElement) !== undefined) {
		// Create new root if the old modalMessage does not exist anymore (e.g. during logout/login)
		if (!modalMessageRoot || !modalMessageRoot._internalRoot.containerInfo.isConnected) {
			modalMessageRoot = ReactDOM.createRoot(document.getElementById("modalMessage") as any);
		}
		modalMessageRoot.render(content);
	} else {
		(modalElement.querySelector("#modalMessage") as HTMLElement).innerText = content as string;
	}
	(modalElement.querySelector("#modalSubmitButtonTitle") as HTMLElement).innerText = submitTitle;

	const modal = new Modal(modalElement, {});

	(modalElement.querySelector("#modalSubmitButton") as HTMLElement).onclick = async () => {
		if (awaitOnSubmit) {
			await (
				onSubmit as (startLoading: () => void, endLoading: () => void, hideModal: () => void) => Promise<void>
			)(
				() => {
					modalElement.querySelector("#modalSubmitButtonSpinner")?.classList.remove("d-none");

					modalElement.querySelector("#modalCancelButton")?.setAttribute("disabled", "true");
					modalElement.querySelector("#modalSubmitButton")?.setAttribute("disabled", "true");
				},
				() => {
					modalElement.querySelector("#modalSubmitButtonSpinner")?.classList.add("d-none");

					modalElement.querySelector("#modalCancelButton")?.removeAttribute("disabled");
					modalElement.querySelector("#modalSubmitButton")?.removeAttribute("disabled");
				},
				() => modal.hide()
			);
		} else {
			(onSubmit as () => void)();
			modal.hide();
		}
	};

	if (options?.infoButton) {
		(modalElement.querySelector("#modalInfoButton") as HTMLElement)?.classList.remove("d-none");

		(modalElement.querySelector("#modalInfoButton i") as HTMLElement)?.setAttribute("class", "");
		(modalElement.querySelector("#modalInfoButton i") as HTMLElement)?.classList.add(
			"bi",
			options!.infoButton.emoji
		);

		setTooltipContent(
			"#modalInfoButton",
			options!.infoButton.content
		);
	} else {
		(modalElement.querySelector("#modalInfoButton") as HTMLElement)?.classList.add("d-none");
	}

	modal.show();
};
